<template>
  <!-- Main Wrapper -->
  <div>
    <div class="content container-fluid">
      <div class="row hr-login">
        <div class="col-sm-12 col-md-8 col-lg-5">
           <div class="logo logo-small mb-20  text-center">
          <img
            :src="setImg('assets/images/logo-small.png')"
            alt="Logo"
            width="100"
            height="80"
          />
        </div>
          <!-- Login Card -->
          <Card cardClasses="card" cardTitle="Login">
            <template v-slot:default>
              <form method="POST" @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        id="email"
                        type="Email"
                        v-model="form.email"
                        class="form-control"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group">
                        <div class="input-group">
                          <input
                              id="password"
                              type="password"
                              v-model="form.password"
                              class="form-control"
                              placeholder="Enter your password"
                              ref="pass"
                          />
                          <span class="input-group-append">
                              <button  class="btn btn-outline-primary" type="button" @click="passToggle">
                                  <i class="fa fa-eye"></i>
                              </button>
                          </span>
                        </div>
                      </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                >
                  Login
                </button>
              </form>
            </template>
          </Card>
          <!-- /Login Card -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import auth from "@/mixins/auth";
import Cookies from "js-cookie";
import {TOKEN} from "@/store/config/constants";

export default {
  name: "AdminLogin",
  components: {
    Card,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  mixins: [auth],
  created() {
    Cookies.remove(TOKEN);
  },
  methods : {
    passToggle() {
      if (this.$refs.pass.getAttribute('type') == 'password')
        this.$refs.pass.setAttribute('type', 'text');
      else
        this.$refs.pass.setAttribute('type', 'password');
    }
  },
};
</script>

<style>
.hr-login {
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>