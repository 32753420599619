import { createLogger, createStore } from 'vuex';
require('../store/config/interceptor');
// import the auto exporter
import modules from '@/store/modules/index';

const debug = process.env.NODE_ENV !== 'production';

// Create store
export default createStore({
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : []
})



