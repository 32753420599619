<template>
  <div>
    <AdminHeader />
    <AdminSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
            title="Administrator Dashboard | Content Management"
            subTitle="Ingredients"
        />

        <div class="row">
          <div
              class="col-sm-12 col"
              style="text-align: right; margin-bottom: 5px"
          >
            <button
                href="#ingredientModal"
                data-toggle="modal"
                class="btn add"
                @click="sendInfo('', 'post')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>

          </div>
        </div>

        <div class="row" style="margin-bottom: 40px">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table tableClasses="card-table" :columns="tableColumns" v-if="ingredients" :meta="meta_ingredients" @getData="updateList">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in ingredients" :key="index">
                  <td v-for="column in tableColumns" :key="column">
                    <span>
                      {{ item[column.replace(/\s/g, "").toLowerCase()] }}
                    </span>

                    <div v-if="column == 'Action'" class="actions">
                      <a
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#ingredientModal"
                          @click="sendInfo(item, 'put')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          data-toggle="modal"
                          href="#deleteModal"
                          class="btn btn-sm bg-danger-light"
                          @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Ingredient Modal -->
  <Modal modalClasses="modal fade" modalId="ingredientModal" :visible="modal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.key">Update Ingredient</h5>
      <h5 class="modal-title" v-else>Add Ingredient</h5>
    </template>
    <form
        :method="formAction"
        @submit.prevent="submitHandler"
    >
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input class="form-control" type="text" placeholder="Name of the Ingredient" required v-model="form.name"/>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">{{ form.key ? 'Update' : 'Add' }}</button>
    </form>
  </Modal>
  <!-- Ingredient Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form
          :method="formAction"
          @submit.prevent="submitHandler"
      >
        <button type="submit" class="btn btn-primary mr-1 py-10">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";

import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";

const tagTableColumns = ["Name", "Action"];
const endpoint = '/ingredient';

export default {
  name: "Ingredients",
  components: {
    PageBreadcrum,
    Table,
    Modal,
  },
  data() {
    return {
      tableColumns: [...tagTableColumns],
      formUrl: endpoint,
      formAction: 'post',
      form: {
        name: "",
        key: "",
      },
      modal: true,
    };
  },
  methods: {
    ...mapActions("Ingredient", [
      "getIngredients",
      "addIngredients",
      "updateIngredients",
      "deleteIngredients",
    ]),
    createOrUpdate: async function () {
      const User = new FormData();
      User.append("name", this.form.name);

      if (this.formAction == "post") {
        let res = await this.addIngredients(User);
        return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        let res = await this.updateIngredients(User);
        return res;
      }
    },
    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";

      if (this.formAction == "delete") {
        this.deleteIngredients(this.form.key).then((res) => {
          this.modal= false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
              Swal.fire({
                title: "Error!",
                text: "Error occurred",
                icon: "error",
                confirmButtonText: "Cool",
              });
            });
      } else {
        this.createOrUpdate().then((res) => {
          this.modal= false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {});
      }
    },
    //for sending information of selected user to modal
    sendInfo(item, formType) {
      if (item) {
        this.formUrl = endpoint + '/' + item.key; // append key in  method
      } else {
        this.formUrl = endpoint;
      }
      this.formAction = formType;
      this.form.name = item.name;
      this.form.key = item.key;
      this.modal= true;
    },
    updateList(url) {
      this.getIngredients(url)
    },
  },
  mounted() {
    this.getIngredients();
  },
  computed: {
    ...mapGetters("Ingredient", ["ingredients", "meta_ingredients"]),
  },
};
</script>

<style>
</style>