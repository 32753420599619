<template>
  <div :class="tableClasses">
    <div class="table-responsive">
      <table class="table table-hover table-center mb-0">
        <thead v-if="columns">
          <tr>
            <!-- making th according to number of columns -->
            <th v-for="column in columns" :key="column">{{ column }}</th>
            <!-- seperate th for action buttons -->
          </tr>
        </thead>
        <tbody>
           <slot></slot>
        </tbody>
        <tfoot v-if="meta" >
        <tr><td colspan="100%"><Paginate :meta="meta" @getData="getData"/></td></tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Paginate from "@/components/Paginate";
export default {
  name: "Table",
  components: {Paginate},
  props: {
    columns: Array,
    meta: Object,
    tableClasses: [String, Object, Array]
  },
  methods: {
    getData(url) {
      this.$emit("getData", url)
    },
  }
};
</script>

<style>
</style>