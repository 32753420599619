<template>
  <div class="custom-element">
    <h1>404 Page Not Found</h1>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
};
</script>

<style scoped>
</style>>
