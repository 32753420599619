<template>
  <div class="custom-element">
    <h1>This is contact us page</h1>
  </div>
</template>

<script>

export default {
  name: "Contact",
  components: {
  },
};
</script>

<style scoped>
</style>>
