import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = 'profile';
const _url2 = 'profiler';

export default {
    namespaced: true,
    state: {
        profile: null
    },
    mutations: {
        loadProfile(state, profile) {
            state.profile = profile;
        },
    },
    actions: {
        async getProfile({ commit }) {

            const res = await axios.get(_url);
            await commit('loadProfile', res.data.data);
            return res;
        },
        async updateProfile({ commit }, Profile) {
            let url = `${_url2}`;
            const res = await axios.post(url, Profile);
            await commit('loadProfile', res.data.data);
            return res;
        },
    },
    modules: {
    },
    getters: {
        profile: state => state.profile
    }
};