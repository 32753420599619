import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";
export default {
    namespaced: true,
    state: {
        user: null,
        profile:null
    },
    mutations: {
        setUser(state, email) {
            state.user = email
        },
        setUserProfile(state, user) {
            state.profile = user;
        },
        LogOut(state) {
            state.user = null
            state.posts = null
        },
    },
    actions: {
        login: async ({ commit }, User) => {
            let url = '/login';
            if (User.get('role') != '') {
                url = User.get('role') + url;
            }
            const res = await axios.post(url, User);

            if (typeof res.data === 'object' && res !== null) {
                Cookies.set(TOKEN, res.data.data.token.access_token);
            }
            else {
                let err = { loginMessage: res.data };
                throw err;
            }
            await commit('setUser', User.get('email'))

            return res;
        },
        getuser: async ({ commit }, role) => {

            let res;
            try {
                if (role != '') {
                    res = await axios.get('user');
                }
                else {
                    res = await axios.get(role);
                }

            }
            catch (e) {
                throw e;
            }

            // await commit('setUser', User.get('email'))

            return res;
        },
        getProfile: async ({ commit }) => {

            let res;
            try {
                res = await axios.get('/profile');
                await commit('setUserProfile', res.data.data);
                return res;
            }
            catch (e) {
                throw e;
            }

            // await commit('setUser', User.get('email'))

            return res;
        },
        signup: async ({ commit }, User) => {
            let url = 'hr';
            const res = await axios.post(url, User);
            if (typeof res.data === 'object' && typeof  res.data.data.token != 'undefined') {
                Cookies.set(TOKEN, res.data.data.token.access_token);
            }
            await commit('setUser', User.get('email'))

            return res;
        },
        async logout({ commit }) {
            let user = null;
            Cookies.remove(TOKEN);
            commit('LogOut', user)
        },
        async clearToken({commit}) {
            Cookies.set(TOKEN, '');
            let user = null
            commit('logout', user)
        },

    },
    modules: {
    },
    getters: {
        isAuthenticated: (state) => !!state.user,
        profile: state => state.profile,
    }
};