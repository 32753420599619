<template>
  <div>
    <HrHeader pageHeading="Login Activity" pageSubHeading="Settings" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-9 col-lg-6">
            <h4 class="mb-4">Where You're Logged in</h4>
            <Card cardClasses="card">
              <template v-slot:default>
                <div class="custom-card-row border-bottom">
                  <div class="d-flex w-100">
                    <div class="custom-card-row-data">
                      <span class="card-icon"
                        ><i class="fa fa-desktop"></i
                      ></span>
                      <div>
                        <div class="heading">Windows</div>
                        <div class="sub-heading">
                          Amsterdam,UK.
                          <span class="badge badge-info ml-1">Active</span>
                        </div>
                      </div>
                    </div>
                    <div class="custom-card-row-right">
                      <i
                        class="fa fa-chevron-down"
                        data-toggle="collapse"
                        data-target="#logout-button1"
                        aria-expanded="false"
                        aria-controls="logout-button1"
                      ></i>
                    </div>
                  </div>
                  <div class="collapse mb-3" id="logout-button1">
                    <button type="button" class="btn btn-primary w-100">Logout</button>
                  </div>
                </div>

                <div class="custom-card-row border-bottom">
                  <div class="d-flex w-100">
                    <div class="custom-card-row-data">
                      <span class="card-icon"
                        ><i class="fa fa-desktop"></i
                      ></span>
                      <div>
                        <div class="heading">Windows</div>
                        <div class="sub-heading">
                          Amsterdam,UK.
                          <span class="ml-1">23 mins ago</span>
                        </div>
                      </div>
                    </div>
                    <div class="custom-card-row-right">
                      <i
                        class="fa fa-chevron-down"
                        data-toggle="collapse"
                        data-target="#logout-button2"
                        aria-expanded="false"
                        aria-controls="logout-button2"
                      ></i>
                    </div>
                  </div>
                  <div class="collapse mb-3" id="logout-button2">
                    <button type="button" class="btn btn-primary w-100">Logout</button>
                  </div>
                </div>

                <div class="custom-card-row">
                  <div class="d-flex w-100">
                    <div class="custom-card-row-data">
                      <span class="card-icon"
                        ><i class="fa fa-desktop"></i
                      ></span>
                      <div>
                        <div class="heading">Mac</div>
                        <div class="sub-heading">
                          Amsterdam,UK.
                          <span class="ml-1">1 Month</span>
                        </div>
                      </div>
                    </div>
                    <div class="custom-card-row-right">
                      <i
                        class="fa fa-chevron-down"
                        data-toggle="collapse"
                        data-target="#logout-button3"
                        aria-expanded="false"
                        aria-controls="logout-button3"
                      ></i>
                    </div>
                  </div>
                  <div class="collapse" id="logout-button3">
                    <button type="button" class="btn btn-primary w-100">Logout</button>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";

export default {
  name: "LoginActivity",
  components: {
    Card,
    HrHeader,
    HrSidebar,
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>