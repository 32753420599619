<template>
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
            subTitle="Corporate Subscriptions"
            title="Administrator Dashboard | Content Management"
        />

        <div class="row">
          <div
              class="col-sm-12 col"
              style="text-align: right; margin-bottom: 5px"
          >
            <button
                class="btn add"
                data-toggle="modal"
                href="#tagModal"
                @click="sendInfo('', 'post', 'company')"
            >
              <i class="fa fa-plus"></i>
              Add Company
            </button>
            <span></span>
            <button
                class="btn add ml-2"
                data-toggle="modal"
                href="#tagModal"
                @click="sendInfo('', 'post', 'hr')"
            >
              <i class="fa fa-plus"></i>
              Add HR
            </button>
            <span></span>
            <button
                class="btn add ml-2"
                data-toggle="modal"
                href="#tagModal"
                @click="sendInfo('', 'post', 'sub')"
            >
              <i class="fa fa-plus"></i>
              Add Subscription
            </button>
            <span></span>
          </div>
        </div>

        <div class="row" style="margin-bottom: 40px">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table
                v-if="subs"
                :columns="tableColumns"
                :meta="submeta" tableClasses="card-table" @getData="updateList"
            >
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in subs" :key="index">
                  <td v-for="column in tableColumns" :key="column">
                    <span>
                      {{ mutateColumnName(item, column) }}
                    </span>

                    <div v-if="column == 'Action'" class="actions">
                      <a
                          v-if="(item['status'] != 'activated') && (item['status'] != 'expired') && (item['status'] != 'rejected')"
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#deleteModal"
                          @click="sendInfo(item, 'put', 'activate')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          v-if="(item['status'] != 'expired') && (item['status'] != 'rejected')"
                          class="btn btn-sm bg-danger-light"
                          data-toggle="modal"
                          href="#deleteModal"
                          @click="sendInfo(item, 'put', 'remove')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Tag Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="tagModal">
    <template v-slot:header>
      <h5 v-if="form.key" class="modal-title">Update Corporate Subscriptions</h5>
      <h5 v-else class="modal-title">Add Corporate Subscriptions</h5>
    </template>
    <form :method="formAction" @submit.prevent="addSub">
      <div class="row">
        <div v-if="action == 'company'" class="col-12 col-sm-12">
          <div class="form-group">
            <input
                v-model="add.company_name"
                class="form-control"
                placeholder="Company Name"
                required
                type="text"
            />
          </div>
        </div>
        <div v-else-if="action == 'hr'" class="col-12 col-sm-12">
          <div class="form-group">
            <input
                v-model="add.hr_name"
                class="form-control"
                placeholder="HR Name"
                required
                type="text"
            />
          </div>
        </div>
        <div v-if="action == 'hr'" class="col-12 col-sm-12">
          <div class="form-group">
            <input
                v-model="add.hr_email"
                class="form-control"
                placeholder="HR Email"
                required
                type="text"
            />
          </div>
        </div>
        <div v-if="action == 'hr'" class="col-12 col-sm-12">
          <div class="form-group">
            <input
                v-model="add.hr_password"
                class="form-control"
                placeholder="HR Password"
                required
                type="password"
            />
          </div>
        </div>
        <div v-if="action == 'sub'" class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="add.plan_id"
                :options="plans"
                :searchable=true
                label="name"
                placeholder="Plans"
                track-by="name"
                @search-change="asyncPlans"
            />
          </div>
        </div>
        <div v-if="action == 'sub' || action == 'hr'" class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="add.company_id"
                :options="companies"
                :searchable=true
                label="name"
                placeholder="Select Company"
                track-by="name"
                @search-change="asyncCompanies"
            />
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block" type="submit">
        {{ form.key ? "Update" : "Add" }}
      </button>
    </form>
  </Modal>
  <!-- Tag Modal -->

  <!-- Delete Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="deleteModal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">{{ form.action }}</h4>
      <p class="mb-4">{{ `Are you sure want to ${form.action}` }}?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button class="btn btn-primary mr-1 py-10" type="submit">Yes</button>
        <button class="btn btn-danger" data-dismiss="modal" type="button">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";

import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import VueMultiselect from 'vue-multiselect';

const tagTableColumns = [
  "Plan",
  "Company",
  "Status",
  "Requested At",
  "Activated/Rejected At",
  "Action",
];
const endpoint = "/tags";

export default {
  name: "Corporate Subscriptions",
  components: {
    PageBreadcrum,
    Table,
    Modal,
    VueMultiselect
  },
  data() {
    return {
      formUrl: endpoint,
      formAction: "post",
      tableColumns: [...tagTableColumns],
      form: {
        name: "",
        key: "",
        action: "",
      },
      add: {
        company_name: "",
        hr_email: "",
        hr_name: "",
        hr_password: "",
        plan_id: "",
        company_id: "",
      },
      action: "",
      modal: false,
      companies: [],
    };
  },
  methods: {
    ...mapActions("Subscription", [
      "loadSubs",
      "addSubs",
      "updateSubs",
      "deleteSubs",
      "activateSubs",
    ]),
    ...mapActions("CorporatePlan", ["getPlans"]),
    createOrUpdate: async function () {
      const User = new FormData();

      if (this.form.action == "activate") {
        User.append("id", this.form.id);
        let res = await this.activateSubs(User);
        return res;
      } else if (this.form.action == "remove") {
        User.append("id", this.form.id);
        // User.append("_method", "PUT");
        let res = await this.deleteSubs(User);
        return res;
      }

    },
    submitHandler(e) {
      this.createOrUpdate()
          .then((res) => {
            this.modal = false;
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
          .catch((e) => {
            console.log(e);
            Swal.fire({
              title: "Error!",
              text: "Error",
              icon: "error",
              confirmButtonText: "Cool",
            });
          });
    },
    //for sending information of selected user to modal
    sendInfo(item, formType, action) {
      this.modal = true;
      this.action = action;
      this.form.action = action;
      this.formAction = formType;
      this.form.id = item.id;
    },
    /**
     * Use it only for the meta values
     */
    mutateColumnName(item, column) {
      if (column === "Plan") {
        return item.plan.name;
      }
      if (column === "Company") {
        return item.company.name;
      }
      if (column === "Status") {
        return item.status;
      }
      if (column === "Requested At") {
        return this.dateFormatter(item.created_at, false);
      }
      if (column === "Activated/Rejected At") {
        if (item.status == "activated") {
          return this.dateFormatter(item.activated_at, false);
        } else if (item.status == "rejected") {
          return this.dateFormatter(item.updated_at, false);
        } else if (item.status == "expired") {
          return this.dateFormatter(item.expires_at, false);
        }
      }
      return item[column.replace(/\s/g, "").toLowerCase()];
    },
    addSub() {
      const User = new FormData();
      if (this.action == "company") {
        User.append("company_name", this.add.company_name);
      } else if (this.action == "hr") {
        User.append("hr_name", this.add.hr_name);
        User.append("hr_email", this.add.hr_email);
        User.append("company_id", this.add.company_id.key);
        User.append("hr_password", this.add.hr_password);
      } else if (this.action == "sub") {
        User.append("company_id", this.add.company_id.key);
        User.append("plan_id", this.add.plan_id.key);
      }
      axios
          .post("admin/corporate-subscriptions/register", User)
          .then((res) => {
            if (typeof res.data != "undefined") {
              this.modal = false;
              this.loadSubs();
              Swal.fire({
                title: "Success!",
                text: `Operation Successful!.`,
                icon: "success",
                confirmButtonText: "Cool",
              });
            }
          })
          .catch((e) => {
          });
    },
    async getCompanies(qry = null) {
      let res = await axios.get("/admin/company", {
        params: {
          all: 1,
          query: qry
        },
      });
      return res;
    },
    updateList(url) {
      this.loadSubs(url)
    },
    asyncPlans(qry) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        this.getPlans(this.meta.links[1].url + "?query=" + qry);
      }, 750);
    },
    asyncCompanies(qry) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        this.getCompanies(qry).then((res) => {
          this.companies = res.data.data;
        });
      }, 750);
    },
  },
  mounted() {
    this.loadSubs();
    this.getPlans();
    this.getCompanies().then((res) => {
      this.companies = res.data.data;
    });
  },
  computed: {
    ...mapGetters("Subscription", ["subs", "submeta"]),
    ...mapGetters("CorporatePlan", ["plans", "meta"]),
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>