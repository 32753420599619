<template>
  <!-- Page Header -->
  <div class="page-header">
    <div class="row">
      <div class="col-sm-12">
        <h3 v-if="title" class="page-title">{{ title }}</h3>
        <h4 v-if="subTitle" class="page-title">{{ subTitle }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageBreadcrum",
  props: {
    title: {
      type: String
    },
    subTitle: {
       type: String
    }
  },
};
</script>

<style>
</style>