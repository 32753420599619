<template>
  <div>
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum title="Profile" />
        <Card cardClasses="card" v-if="profile">
          <template v-slot:default>
            <div class="row">
              <div class="col-12 col-lg-6 profile-image text-center">
                <!--                    <a href="#">-->
                <ImageUpload
                    _name="profile_pic"
                    id="profile_pic"
                    :img="form.profile_pic"
                    ref="img"
                    img_class="rounded-circle"
                />
                <!--                    </a>-->
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <form
                    @submit.prevent="submitHandler"
                >
                  <div class="row form-row mb-3 mt-3">
                    <div class="col-3">
                      <label for="name">Name</label>
                    </div>
                    <div class="col-9">
                      <input
                          id="name"
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          v-model="form.name"
                          required
                      />
                    </div>
                  </div>
                  <div class="row form-row mb-1">
                    <div class="col-3">
                      <label for="old_password">Old Password</label>
                    </div>
                    <div class="col-9">
                      <input
                          id="old_password"
                          type="password"
                          placeholder="Old Password"
                          class="form-control"
                          v-model="form.old_password"
                      />
                    </div>
                  </div>
                  <div class="row form-row mb-1">
                    <div class="col-3">
                      <label for="new_password">New Password</label>
                    </div>
                    <div class="col-9">
                      <input
                          id="new_password"
                          type="password"
                          placeholder="New Password"
                          class="form-control"
                          v-model="form.new_password"
                      />
                    </div>
                  </div>
                  <div class="row form-row mb-1">
                    <div class="col-3">
                      <label for="new_password_cnfrm">New Password Confirmation</label>
                    </div>
                    <div class="col-9">
                      <input
                          id="new_password_cnfrm"
                          type="password"
                          placeholder="New Password Confirmation"
                          class="form-control"
                          v-model="form.new_password_cnfrm"
                      />
                    </div>
                  </div>
                  <div class="row form-row">
                    <div class="col-9 offset-3 text-center">
                      <button type="submit" class="btn btn-primary w-100 add mt-3">Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import ImageUpload from "@/components/Form/ImageUpload";
import Card from "@/components/Card";
import PageBreadcrum from "@/components/PageBreadcrum";

export default {
name: "AdminProfile",
  components: {
    PageBreadcrum,
    ImageUpload,
    Card,
  },
  data() {
  return {
    form: {}
  }
  },
  methods: {
    ...mapActions("Profiles", [
      "getProfile",
      "updateProfile",
    ]),
    update: async function () {
      const User = new FormData();
      User.append("name", this.form.name);
      if(this.$refs.img.getFile())
        User.append("profile_pic", this.$refs.img.getFile());
      if (this.form.old_password)
        User.append("old_password", this.form.old_password);
      if (this.form.new_password)
        User.append("new_password", this.form.new_password);
      if (this.form.new_password_cnfrm)
        User.append("new_password_confirmation", this.form.new_password_cnfrm);
      let res= await this.updateProfile(User);
      return res;
    },
    submitHandler(e) {
      this.update().then((res) => {
        Swal.fire({
          title: "Success!",
          text: `Operation Successful!`,
          icon: "success",
          confirmButtonText: "Cool",
        });
      })
          .catch((e) => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.getProfile();
  },
  watch: {
    profile(value) {
      this.form.name = value.name;
      this.form.email = value.email;
      this.form.profile_pic = value.profile_pic;
    }
},
  computed: {
    ...mapGetters("Profiles", ["profile"]),
  },
}
</script>

<style scoped>

</style>