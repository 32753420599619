import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = '/tickets';

export default {
    namespaced: true,
    state: {
        tickets: null,
        meta:null
    },
    mutations: {
        loadTickets(state, tickets) {
            state.tickets = tickets.data;
            state.meta = tickets.meta;
        },
        addTickets(state, ticket) {
            if (state.tickets) state.tickets.unshift(ticket);
        },
        updateTickets(state, ticket) {
            let index= state.tickets.findIndex(t => t.key === ticket.key);
            state.tickets[index] = ticket;
        },
        deleteTickets(state, ticket) {
            let index= state.tickets.findIndex(t => t.key === ticket);
            state.tickets.splice(index, 1);
        }
    },
    actions: {
        async getTickets({ commit }, url=null) {

            const res = await axios.get( url ?? _url);
            await commit('loadTickets', res.data);
            return res;
        },
        async addTickets({ commit }, Ticket) {
            const res = await axios.post(_url, Ticket);
            await commit('addTickets', res.data.data);
            return res;
        },
        async updateTickets({ commit }, Ticket) {
            let url = `${_url}/${Ticket.get('key')}`;
            const res = await axios.post(url, Ticket);
            await commit('updateTickets', res.data.data);
            return res;
        },
        async deleteTickets({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteTickets', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        tickets: state => state.tickets,
        meta: state=>state.meta
    }
};