<template>
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum title="Support Tickets"/>
        <Card :cardTitle="salesCard.title">
          <template v-slot:default>
            <slot>
              <div class="row">
                <div class="col-4">
                  <Form
                      :fAction="getUrl"
                      fMethod="patch"
                      @syncerMethod="getEndpoint()"
                  >
                    <VueMultiselect
                        v-model="spStatus"
                        :options="_status"
                        :searchable="false"
                        open-direction="bottom"
                        placeholder="Set Status"
                        @close="submitHandler"
                    />
                    <input :value="spStatus" name="status" type="hidden"/>
                    <button class="mt-1 btn btn-sm btn-success">Update</button>
                  </Form>
                </div>
                <div class="col-4">
                  <Form
                      :fAction="getUrl"
                      fMethod="patch"
                      @syncerMethod="getEndpoint()"
                  >
                    <VueMultiselect
                        v-model="spPriority"
                        :options="_priority"
                        :searchable="false"
                        open-direction="bottom"
                        placeholder="Set Priority"
                    />
                    <input :value="spPriority" name="priority" type="hidden"/>
                    <button class="mt-1 btn btn-sm btn-success">Update</button>
                  </Form>
                </div>
              </div>
              <hr/>
              <div>Subject : {{ setTicket.subject }}</div>
              <div>Started by : {{ setTicket.submitted_by }}</div>
              <div>Started At : {{ dateFormatter(setTicket.created_at) }}</div>
              <div v-if="setTicket.attachment">
                <img :src="setTicket.attachment" alt="" width="100"/>
              </div>
            </slot>
          </template>
        </Card>

        <div class="d-flex justify-content-center row suport-index">
          <div class="col-md-6">
            <div class="d-flex flex-column comment-section support-ticket">
              <div
                  v-for="comment in setTicket.replies"
                  :key="comment.key"
                  class="search-container"
              >
                <div class="st-body">
                  <div class="avatar">
                    <img
                        :src="comment['profile_pic']"
                        class="rounded-circle"
                        height="40"
                        width="40"
                    />
                  </div>
                  <div class="ticket-content">
                    <p class="ticket-description">{{ comment["reply"] }}</p>
                    <template v-if="comment['attachment']">
                      <img :src="comment['attachment']" width="100"/>
                    </template>
                  </div>
                </div>

                <div class="st-foot">
                  <span class="label" style="font-weight: bold">{{
                      comment["name"]
                    }}</span
                  ><span class="label">Shared:</span
                ><span class="value">{{
                    dateFormatter(comment["created_at"])
                  }}</span>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <Form
                :fAction="formUrl"
                :fMethod="formAction"
                :isMultiPart="true"
                @syncerMethod="getEndpoint()"
            >
              <div class="bg-white">
                <div class="d-flex flex-row fs-12">
                  <!--                    <div class="like p-2 cursor"><i class="fa fa-commenting-o"></i><span class="ml-1">Comment</span></div>-->
                </div>
              </div>
              <div class="bg-light p-2">
                <div class="d-flex flex-row align-items-start">
                  <img
                      v-if="profile"
                      :src="profile.profile_pic"
                      class="rounded-circle"
                      width="40"
                  />
                  <textarea
                      class="form-control ml-1 shadow-none textarea"
                      name="reply"
                      required
                  ></textarea>
                </div>
                <div class="flex-start flex-row align-items-start">
                  <label for="attachment">
                    Attachment
                    <ImageUpload id="attachment" ref="img" _name="attachment"/>
                  </label>
                </div>
                <div class="mt-2 text-right">
                  <button
                      class="btn btn-primary btn-sm shadow-none"
                      type="submit"
                  >
                    Post comment
                  </button>
                  <router-link :to="{ name: 'SupportTicket' }">
                    <button
                        class="btn btn-outline-primary btn-sm ml-1 shadow-none"
                        type="button"
                    >
                      Cancel
                    </button>
                  </router-link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import axios from "axios";

import ImageUpload from "@/components/Form/ImageUpload";
import Form from "@/components/Form/Form";
import VueMultiselect from 'vue-multiselect';
import {mapGetters} from "vuex";

const endpoint = "/tickets/";

export default {
  name: "Comments",
  components: {
    Form,
    ImageUpload,
    Card,
    VueMultiselect,
  },
  data() {
    return {
      salesCard: {
        title: "Comments",
      },
      _status: [
        "Waiting",
        "Open",
        "Replied",
        "Closed",
      ],
      _priority: [
        "Low Priority",
        "Medium Priority",
        "High Priority",
      ],
      spStatus: null,
      spPriority: null,
      setTicket: {},
      formUrl: endpoint + this.$route.query.ticketKey + "/reply",
      formAction: "post",
      getUrl: endpoint + this.$route.query.ticketKey,
    };
  },
  methods: {
    getEndpoint() {
      this.$refs.img.resetFile();
      axios
          .get(this.getUrl)
          .then((res) => {
            this.setTicket = res.data.data;
            this.spStatus = this.setTicket.status;
            this.spPriority = this.setTicket.priority;
          })
          .catch((err) => console.log(err));
    },
    submitHandler() {
    },
  },
  mounted() {
    this.getEndpoint();
  },
  computed: {
    ...mapGetters("Profiles", ["profile"]),
  },
};
</script>

<style scoped>
body {
  background: #eee;
}

.date {
  font-size: 14px;
}

.comment-text {
  font-size: 15px;
  font-weight: 600;
}

.fs-12 {
  font-size: 12px;
}

.shadow-none {
  box-shadow: none;
}

.name {
  color: #007bff;
}

.cursor:hover {
  color: blue;
}

.cursor {
  cursor: pointer;
}

.textarea {
  resize: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>