// vue.config.js
module.exports = {
   // publicPath: process.env.NODE_ENV === 'production' ? '/' : '/',
   publicPath: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PROD_URL : '/',
   
   pages: {
      index: {
         // entry for the page
         entry: './src/views/Index/main.js',
         // the source template
         template: 'public/index.html',
         // output as dist/index.html
         filename: 'index.html',
         // when using title option,
         // template title tag needs to be <title><%= htmlWebpackPlugin.options.title %></title>
         title: 'Live Happy',
         // chunks to include on this page, by default includes
         // extracted common chunks and vendor chunks.
         chunks: ['chunk-vendors', 'chunk-common', 'index']
      },
      admin: {
         entry: './src/views/Admin/main.js',
         template: 'public/admin/index.html',
         filename: 'admin/index.html',
         title: 'Live Happy',
         chunks: ['chunk-vendors', 'chunk-common', 'admin']
      },
      HR: {
         entry: './src/views/HR/main.js',
         template: 'public/HR/index.html',
         filename: 'HR/index.html',
         title: 'Live Happy',
         chunks: ['chunk-vendors', 'chunk-common', 'HR']
      },
   }
}

