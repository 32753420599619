import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "category";

export default {
    namespaced: true,
    state: {
        categories: null,
        sub_categories: null,
        meta_categories: null,
        meta_sub_categories: null,
    },
    mutations: {
        loadCategories(state, categories) {
            state.categories = categories.data;
            state.meta_categories = categories.meta;
        },
        addCategories(state, category) {
            state.categories.unshift(category);
        },
        updateCategories(state, category) {
            let index= state.categories.findIndex(t => t.key === category.key);
            state.categories[index] = category;
        },
        deleteCategories(state, category) {
            let index= state.categories.findIndex(t => t.key === category);
            state.categories.splice(index, 1);
        },
        loadSubCategories(state, sub_categories) {
            state.sub_categories = sub_categories.data;
            state.meta_sub_categories = sub_categories.meta;
        },
        addSubCategories(state, category) {
            state.sub_categories.unshift(category);
        },
        updateSubCategories(state, category) {
            let index= state.sub_categories.findIndex(t => t.key === category.key);
            state.sub_categories[index] = category;
        },
        deleteSubCategories(state, category) {
            let index= state.sub_categories.findIndex(t => t.key === category);
            state.sub_categories.splice(index, 1);
        }
    },
    actions: {
        async getCategories({ commit }, url = null) {

            const form = new FormData();

            form.append('c_type', 'cat');
            form.append('total_sub_cat', true);
            form.append('parent', true);
            form.append('total_video', true);

            let parameters = [...form.entries()]
                .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')

            const res = await axios.get(url ?? (_url + '?' + parameters));
            await commit('loadCategories', res.data);
            return res;
        },
        async addCategories({ commit }, Category) {
            const res = await axios.post(_url, Category.Form);
            let parent_key  = Category.Form.get('parent');
            let index= Category.parent.findIndex(t => t.key === parent_key);
            let cat_structure = {
                key: res.data.data.key,
                name:  res.data.data.name,
                children: [],
                parent: {
                    key : parent_key,
                    name : Category.parent[index]['name']
                },
                total_sub_cat: res.data.data.total_sub_cat,
                total_video: res.data.data.total_video,
            };
            await commit('addCategories', cat_structure);
            return res;
        },
        async updateCategories({ commit }, Category) {
            let url = `${_url}/${Category.Form.get('key')}`;
            const res = await axios.post(url, Category.Form);

            let cat_structure = {
                key: res.data.data.key,
                name:  res.data.data.name,
                children: [],
                parent: {
                    key : res.data.data.parent.key,
                    name : res.data.data.parent.name
                },
                total_sub_cat: res.data.data.total_sub_cat,
                total_video: res.data.data.total_video,
            };

            await commit('updateCategories', cat_structure);
            return res;
        },
        async deleteCategories({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            if (typeof res.data != 'undefined')
                await commit('deleteCategories', key);
            return res;
        },
        async getSubCategories({ commit }, url = null) {

            const form = new FormData();
            form.append('c_type', 'sub_cat');
            form.append('sup_parent', true);
            form.append('parent', true);
            form.append('total_video', true);
            let parameters = [...form.entries()]
                .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')

            const res = await axios.get(url ?? (_url + '?' + parameters));
            await commit('loadSubCategories', res.data);
            return res;
        },
        async addSubCategories({ commit }, Category) {
            const res = await axios.post(_url, Category.Form);

            let cat_structure = {
                key: res.data.data.key,
                name:  res.data.data.name,
                children: [],
                parent: {
                    key : res.data.data.parent.key,
                    name : res.data.data.parent.name
                },
                total_sub_cat: res.data.data.total_sub_cat,
                total_video: res.data.data.total_video,
                super_parent: res.data.data.super_parent,
            };
            await commit('addSubCategories', cat_structure);
            return res;
        },
        async updateSubCategories({ commit }, Category) {
            let url = `${_url}/${Category.Form.get('key')}`;
            const res = await axios.post(url, Category.Form);

            let cat_structure = {
                key: res.data.data.key,
                name:  res.data.data.name,
                children: [],
                parent: {
                    key : res.data.data.parent.key,
                    name : res.data.data.parent.name
                },
                total_sub_cat: res.data.data.total_sub_cat,
                total_video: res.data.data.total_video,
                super_parent: res.data.data.super_parent,
            };
            console.log(cat_structure, 'update sub');
            await commit('updateSubCategories', cat_structure);
            return res;
        },
        async deleteSubCategories({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            if (typeof res.data != 'undefined')
                await commit('deleteSubCategories', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        categories: state => state.categories,
        sub_categories: state => state.sub_categories,
        meta_categories: state => state.meta_categories,
        meta_sub_categories: state => state.meta_sub_categories,
    }
};