import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "users";

export default {
    namespaced: true,
    state: {
        employees: null,
        meta: null,
        employeesD: null,
        metaD: null,
    },
    mutations: {
        loadEmployees(state, employees) {
            state.employees = employees.data;
            state.meta = employees.meta;
        },
        loadEmployeesD(state, employees) {
            state.employeesD = employees.data;
            state.metaD = employees.meta;
        },
        addEmployees(state, employee) {
            state.employees.unshift(employee);
        },
        updateEmployees(state, employee) {
            let index= state.employees.findIndex(t => t.key === employee.key);
            state.employees[index] = employee;
        },
        deleteEmployees(state, employee) {
            let index= state.employees.findIndex(t => t.key === employee);
            state.employees.splice(index, 1);
        },
        updateEmployeesD(state, employee) {
            let index= state.employeesD.findIndex(t => t.key === employee.key);
            state.employeesD[index] = employee;
        },
        deleteEmployeesD(state, employee) {
            let index= state.employeesD.findIndex(t => t.key === employee);
            state.employeesD.splice(index, 1);
        }
    },
    actions: {
        async getEmployees({ commit }, url = null) {
            const res = await axios.get( url ?? _url+"?active=1");
            await commit('loadEmployees', res.data);
            return res;
        },
        async addEmployees({ commit }, Employee) {
            const res = await axios.post(_url, Employee);
            await commit('addEmployees', res.data.data);
            return res;
        },
        async updateEmployees({ commit }, Employee) {
            let url = `${_url}/${Employee.get('key')}`;
            const res = await axios.post(url, Employee);
            await commit('updateEmployees', res.data.data);
            return res;
        },
        async deleteEmployees({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteEmployees', key);
            return res;
        },
        async getEmployeesD({ commit }, url = null) {
            const res = await axios.get( url ?? _url+"?active=0");
            await commit('loadEmployeesD', res.data);
            return res;
        },
        async updateEmployeesD({ commit }, Employee) {
            let url = `${_url}/${Employee.get('key')}`;
            const res = await axios.post(url, Employee);
            await commit('updateEmployeesD', res.data.data);
            return res;
        },
        async deleteEmployeesD({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteEmployeesD', key);
            return res;
        },



    },
    modules: {
    },
    getters: {
        employees: state => state.employees,
        meta: state => state.meta,
        employeesD: state => state.employeesD,
        metaD: state => state.metaD,
    }
};