import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";
import router from "@/router";

const _url = '/tickets';

export default {
    namespaced: true,
    state: {
        comments: null
    },
    mutations: {
        loadComments(state, comments) {
            state.comments = comments;
        },
        addComments(state, comment) {
            if (state.comments) state.comments.replies.push(comment);
        },
        updateComments(state, comment) {
            let index= state.comments.findIndex(t => t.key === comment.key);
            state.comments[index] = comment;
        },
        deleteComments(state, comment) {
            let index= state.comments.findIndex(t => t.key === comment);
            state.comments.splice(index, 1);
        }
    },
    actions: {
        async getComments({ commit }) {
            const _ticketKey = router.currentRoute.value.query.ticketKey;
            const res = await axios.get(`${_url}/${_ticketKey}`);

            await commit('loadComments', res.data.data);
            return res;
        },
        async addComments({ commit }, Comment) {
            const _ticketKey = router.currentRoute.value.query.ticketKey;
            const res = await axios.post(`${_url}/${_ticketKey}/reply`, Comment);
            await commit('addComments', res.data.data);
            return res;
        },
        /*async updateComments({ commit }, Comment) {
            let url = `${_url}/${Comment.get('key')}`;
            const res = await axios.post(url, Comment);
            await commit('updateComments', res.data.data);
            return res;
        },
        async deleteComments({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteComments', key);
            return res;
        },*/
    },
    modules: {
    },
    getters: {
        comments: state => state.comments
    },
};