import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "recipe";

export default {
    namespaced: true,
    state: {
        recipes: null,
        meta_recipes: null,
    },
    mutations: {
        loadRecipes(state, recipes) {
            state.recipes = recipes.data;
            state.meta_recipes = recipes.meta;
        },
        addRecipes(state, recipe) {
            state.recipes.unshift(recipe);
        },
        updateRecipes(state, recipe) {
            let index= state.recipes.findIndex(t => t.key === recipe.key);
            state.recipes[index] = recipe;
        },
        deleteRecipes(state, recipe) {
            let index= state.recipes.findIndex(t => t.key === recipe);
            state.recipes.splice(index, 1);
        }
    },
    actions: {
        async getRecipes({ commit }, url = _url) {
            const res = await axios.get(url)

            await commit('loadRecipes', res.data);
            return res;
        },
        async addRecipes({ commit }, Recipe) {
            const res = await axios.post(_url, Recipe);
            await commit('addRecipes', res.data.data);
            return res;
        },
        async updateRecipes({ commit }, Recipe) {
            let url = `${_url}/${Recipe.get('key')}`;
            const res = await axios.post(url, Recipe);
            console.log(res.data.data, 'update');
            await commit('updateRecipes', res.data.data);
            return res;
        },
        async deleteRecipes({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteRecipes', key);
            return res;
        },



    },
    modules: {
    },
    getters: {
        recipes: state => state.recipes,
        meta_recipes: state => state.meta_recipes
    }
};