import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = 'ingredient';

export default {
    namespaced: true,
    state: {
        ingredients: null,
        meta_ingredients: null,
    },
    mutations: {
        loadIngredients(state, ingredients) {
            state.ingredients = ingredients.data;
            state.meta_ingredients = ingredients.meta;
        },
        addIngredients(state, ingredient) {
            state.ingredients.unshift(ingredient);
        },
        updateIngredients(state, ingredient) {
            let index= state.ingredients.findIndex(t => t.key === ingredient.key);
            state.ingredients[index] = ingredient;
        },
        deleteIngredients(state, ingredient) {
            let index= state.ingredients.findIndex(t => t.key === ingredient);
            state.ingredients.splice(index, 1);
        }
    },
    actions: {
        async getIngredients({ commit }, url = _url) {

            const res = await axios.get(url);
            await commit('loadIngredients', res.data);
            return res;
        },
        async addIngredients({ commit }, Ingredient) {
            const res = await axios.post(_url, Ingredient);
            await commit('addIngredients', res.data.data);
            return res;
        },
        async updateIngredients({ commit }, Ingredient) {
            let url = `${_url}/${Ingredient.get('key')}`;
            const res = await axios.post(url, Ingredient);
            await commit('updateIngredients', res.data.data);
            return res;
        },
        async deleteIngredients({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteIngredients', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        ingredients: state => state.ingredients,
        meta_ingredients: state => state.meta_ingredients
    }
};