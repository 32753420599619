<template>
  <!-- Main Wrapper -->
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
            subTitle="Videos"
            title="Administrator Dashboard | Content Management"
        />

        <div class="d-flex justify-content-end">
          <div
              class="form-inline"
              style="text-align: right; margin-bottom: 5px"
          >
            <div class="form-group mr-2">
              <input v-model="filterQuery" class="form-control" placeholder="Search by Name" type="text"/>
            </div>
            <button
                class="btn add text-right mr-2"
                @click="filterTable"
            >
              <i class="fa fa-filter"></i> Search
            </button>
            <button
                class="btn add"
                data-toggle="modal"
                href="#videoModal"
                @click="sendInfo( '', 'post')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>

          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table v-if="videos" :columns="tableColumns" :meta="meta_videos" tableClasses="card-table"
                   @getData="updateList">
              <template v-slot:default>
                <tr v-for="(item, index) in videos" :key="index">
                  <td><span>
                    <a data-toggle="modal"
                       href="#videoPlayer"
                       @click="playVideo(item['video_name'])">
                       <img :src="item['img']" style="width: 53px"/>
                      </a>
                  </span></td>
                  <td>
                    <span>
                      {{ item['title'] }}
                    </span>
                  </td>
                  <td><span>{{ item['class_type']['name'] }}</span></td>
                  <td><span class="text-capitalize">{{ slugReverser(item['type']) }}</span></td>
                  <td><span>{{ item['trainer']['name'] }}</span></td>
                  <td><span>{{ item['time'] }}</span></td>
                  <td><span v-for="(tags, index) in item['tags']" :key="index">{{ tags['name'] }} <template
                      v-if="index != (item['tags'].length - 1)">, </template> </span></td>
                  <!-- last column for adding modal buttons -->
                  <td>
                    <div class="actions">
                      <a
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#videoModal"
                          @click="sendInfo(item, 'put')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          class="btn btn-sm bg-danger-light"
                          data-toggle="modal"
                          href="#deleteModal"
                          @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Video Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="videoModal">
    <template v-slot:header>
      <h5 v-if="form.key" class="modal-title">Update Video</h5>
      <h5 v-else class="modal-title">Add Video</h5>
    </template>
    <form
        :method="formAction"
        @submit.prevent="submitHandler"
    >
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input v-model="form.title" class="form-control" name="title" placeholder="Name of the Video" required
                   type="text"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <VueMultiselect
                v-model="sPValue"
                :allow-empty="false"
                :options="setVideo.super_parent"
                :searchable="false"
                label="name"
                open-direction="bottom"
                placeholder="Select Section"
                track-by="name"
                value="name"
                @select="setParentSection"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <VueMultiselect
                v-model="sPCategory"
                :options="setVideo.category"
                label="name"
                open-direction="bottom"
                placeholder="Select Category"
                track-by="name"
                @search-change="asyncSPCategories"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <VueMultiselect
                id="trainer"
                v-model="sPTrainer"
                :options="setVideo.trainers"
                :searchable=true
                label="name"
                open-direction="bottom"
                placeholder="Select Trainer"
                trackBy="name"
                @search-change="asyncTrainers"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <VueMultiselect
                v-model="sPTime"
                :options="setVideo.time"
                label="name"
                open-direction="bottom"
                placeholder="Select Duration"
                track-by="name"
            >
            </VueMultiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <label for="video_file">
              Video
              <input
                  id="video_file"
                  accept=".mp4,.mov,.ogg,.x-flv"
                  class="form-control"
                  placeholder="Upload Video"
                  type="file"
                  @change="uploadVideo"
              />
            </label>
            <input v-model="video_name" name="video_name" type="hidden"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <label for="img">
            Image
            <ImageUpload
                id="img"
                ref="img"
                :img="form.img"
                _name="img"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="sPTags"
                :multiple="true"
                :options="setVideo.tags"
                label="name"
                open-direction="bottom"
                placeholder="Select Tags"
                track-by="key"
                @search-change="asyncTags"
            />
          </div>
        </div>
      </div>
      <button :disabled="in_progress" class="btn btn-primary btn-block" type="submit">
        {{ form.key ? 'Update' : 'Add' }}
      </button>
    </form>
  </Modal>
  <!-- /Video Modal -->

  <Modal :visible="modal" modalClasses="modal fade" modalId="videoPlayer">
    <div v-html="videoPlayer"></div>
  </Modal>

  <!-- Delete Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="deleteModal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form
          :method="formAction"
          @submit.prevent="submitHandler"
      >
        <button class="btn btn-primary mr-1 py-10" type="submit">Yes</button>
        <button class="btn btn-danger" data-dismiss="modal" type="button">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";
import Export from "../../../vue.config";
import VueMultiselect from 'vue-multiselect';
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import ImageUpload from "@/components/Form/ImageUpload";


const tableColumns = [
  "Video",
  "Name",
  "Category",
  "Section",
  "Trainer",
  "Duration",
  "Tags",
  "Action"
];

const endpoint = "/video";
const categoryEndpoint = 'category';
const trainerEndpoint = 'trainer';
const timeEndpoint = 'duration-by-param';
const tagEndpoint = 'tags';
const uploadEndpoint = 'admin/vimeo';
const videoPlayerEndpoint = 'https://player.vimeo.com';

export default {
  name: "Videos",
  components: {
    PageBreadcrum,
    Table,
    Modal,
    VueMultiselect,
    ImageUpload
  },
  data() {
    return {
      sPValue: null,
      sPCategory: null,
      sPTrainer: null,
      sPTime: null,
      sPTags: [],
      tableColumns: [...tableColumns],
      setVideo: {
        super_parent: [],
        category: [],
        trainers: [],
        time: [],
        tags: [],
      },
      formUrl: endpoint,
      formAction: 'post',
      in_progress: false,
      video_name: String,
      videoPlayer: null,
      app_url: Export.publicPath,
      pagination: {
        trainers: 1,
      },
      form: {
        key: null,
        title: null,
        img: null,
        type: null,
        video_name: null,
        time: null,
        class_type: {},
        trainer: [],
        tags: [],
      },
      modal: true,
      filterQuery: "",
    };
  },
  methods: {
    ...mapActions("Video", [
      "getVideos",
      "addVideos",
      "updateVideos",
      "deleteVideos",
    ]),
    createOrUpdate: async function () {
      const Video = new FormData();
      Video.append("title", this.form.title);
      if (this.$refs.img.getFile())
        Video.append("img", this.$refs.img.getFile());
      Video.append("class_type", this.sPCategory.key);
      Video.append("trainer", this.sPTrainer.key);
      Video.append("time", this.sPTime.name);
      Video.append("video_name", this.video_name);
      this.sPTags.forEach((i, j) => {
        Video.append("tags[]", i.key);
      });


      if (this.formAction == "post") {
        let res = await this.addVideos(Video);
        return res;
      } else if (this.formAction == "put") {
        Video.append("key", this.form.key);
        Video.append("_method", "PATCH");
        let res = await this.updateVideos(Video);
        return res;
      }
    },
    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";

      if (this.formAction == "delete") {
        this.deleteVideos(this.form.key).then((res) => {
          this.modal = false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
              Swal.fire({
                title: "Error!",
                text: "Error occurred",
                icon: "error",
                confirmButtonText: "Cool",
              });
            });
      } else {
        this.in_progress = true;
        this.createOrUpdate().then((res) => {
          this.in_progress = false;
          this.modal = false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
            });
      }
    },

    //for sending information of selected user to modal
    sendInfo(item, formType) {
      this.video_name = '';
      this.$refs.img.resetFile();
      if (item) {
        this.video_name = item.video_name;
        this.formUrl = endpoint + '/' + item.key; // append key in  method
      } else {
        this.formUrl = endpoint;
      }
      this.formAction = formType;
      this.form.title = item.title;
      this.form.key = item.key;

      if (formType == 'put' || formType == 'patch') {
        this.sPValue = {name: this.slugReverser(item.type).replace(/\b\w/g, l => l.toUpperCase()), key: item.key};  // to return capitalize word
        this.sPTrainer = item.trainer;
        if (item.time == '10 Min') {
          this.sPTime = {name: item.time, key: 1};
        } else if (item.time == '20 Min') {
          this.sPTime = {name: item.time, key: 2};
        } else {
          this.sPTime = {name: item.time, key: 3};
        }
        this.sPTags = [...item.tags];
        this.sPCategory = {...item.class_type};

      } else {
        this.sPValue = null;
        this.sPCategory = null;
        this.sPTrainer = null;
        this.sPTime = null;
        this.sPTags = null;
      }
      this.modal = true;
    },
    getVideoCategory() {
      const form = new FormData();
      form.append('is_for_video', true);
      form.append('is_hot_topic', true);
      let parameters = [...form.entries()]
          .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')
      axios.get(categoryEndpoint + '/cat/super-cat' + '?' + parameters)
          .then((res) => {
            this.setVideo.super_parent = res.data.data;
          })
          .catch(err => console.log(err))
    },
    slugReverser(data) {
      return data.replace('_', ' ');
    },
    getChildCategory(name, query = null) {
      const super_parent_name = name;
      const form = new FormData();

      this.sPCategory = null;  // to reset the selected category

      form.append('list_from', super_parent_name);
      if (typeof query != null && typeof query != 'object')
        form.append('query', query);
      let parameters = [...form.entries()]
          .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')
      axios.get(categoryEndpoint + '?' + parameters)
          .then(res => {
            let dta = res.data.data.map((data, i) => {
              var _data = [{
                'key': data.key,
                'name': data.name
              }];
              return [..._data, ...data.children];
            })
            this.setVideo.category = dta.flat(1);
          })
          .catch(err => console.log(err))
    },
    getExtraData(url, type) {
      axios.get(url)
          .then(res => {
            if (type != "time") {
              this.setVideo[type] = res.data.data;
            } else {
              this.setVideo[type] = [];
              Object.entries(res.data.data).map(([i, e]) => {
                this.setVideo[type].push({name: e, key: i});
              });
            }
          })
          .catch(err => console.log(err));
    },
    uploadVideo(event) {
      this.in_progress = true;
      let data = new FormData();
      data.append('video', event.target.files[0])
      axios.post(uploadEndpoint, data)
          .then(res => {
            this.video_name = res.data.data.video_path;
            return axios.put(uploadEndpoint, {
              video_uri: res.data.data.video_path
            })
          })
          .then(res => {
            this.in_progress = false
          })
          .catch(err => console.log(err));
    },
    playVideo(video) {
      let _video = video.replace('videos', 'video');
      this.videoPlayer = `
      <iframe src="${videoPlayerEndpoint}${_video}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen width="100%" height="100%"></iframe>
      `;
      /*var raw = {
        "video_uri": video
      };
      axios.get(uploadEndpoint, {
        params : raw
      }).then(res=>this.videoPlayer = res.data.data.embed.html)
          .catch(err=>console.log(err))*/
    },
    setImg(meta) {
      let url = this.app_url;
      if (url.charAt(url.length - 1) == '/') // it will check n remove /
        url = url.slice(0, url.length - 1);
      return `${url}/${meta}`;
    },


    getSuperParent(object) {
      let obj = [...object];
      if (obj.length) {
        return obj.map((e, i) => {
          return [{value: e.key, name: e.name}];
        });
      }
      return [];
    },
    updateList(url) {
      this.getVideos(url)
    },
    asyncSPCategories(query) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        if (this.sPValue && query)
          this.getChildCategory(this.sPValue.name, query);
      }, 750);
    },
    asyncTrainers(query) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        if (query) {
          this.sPTrainer = null;
          this.getExtraData(`${trainerEndpoint}?query=${query}`, 'trainers');
        }
      }, 750);
    },
    asyncTags(query) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        this.getExtraData(`${tagEndpoint}?query=${query}`, 'tags');
      }, 750);
    },
    setParentSection() {
      this.sPCategory = "";
      setTimeout(() => {
        this.setVideo.category = [];
      }, 400);
    },
    filterTable() {
      this.getVideos(`video?search_query=${this.filterQuery}&is_hot_topic=1&search_from[]=Physically Happy&search_from[]=Mentally Happy&search_type[]=title`);
    },
  },
  created() {
    this.getVideoCategory();
    // this.getExtraData(trainerEndpoint, 'trainers');
    this.getExtraData(timeEndpoint + '?all_duration=1', 'time');
    this.getExtraData(tagEndpoint, 'tags');
  },
  mounted() {
    this.getVideos();
  },
  computed: {
    ...mapGetters("Video", ["videos", "meta_videos"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>