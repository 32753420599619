import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = 'admin/corporate-subscriptions';

export default {
    namespaced: true,
    state: {
        subs: null,
        submeta: null,
    },
    mutations: {
        loadSubs(state, subs) {
            state.subs = subs.data;
            state.submeta = subs.meta;
        },
        addSubs(state, sub) {
            state.subs.unshift(sub);
        },
        updateSubs(state, sub) {
            let index= state.subs.findIndex(t => t.id === sub.id);
            state.subs[index] = sub;
        },
        activateSubs(state, sub) {
            let index= state.subs.findIndex(t => t.id === sub.id);
            state.subs[index] = sub;
        },
        deleteSubs(state, sub) {
            let index= state.subs.findIndex(t => t.id === sub.id);
            state.subs[index] = sub;
        }
    },
    actions: {
        async loadSubs({ commit }, url = _url) {

            const res = await axios.get(url);
            await commit('loadSubs', res.data);
            return res;
        },
        async addSubs({ commit }, sub) {
            const res = await axios.post(_url, sub);
            await commit('addSubs', res.data.data);
            return res;
        },
        async updateSubs({ commit }, sub) {
            let url = `${_url}/${sub.get('id')}`;
            const res = await axios.post(url, sub);
            await commit('updateSubs', res.data.data);
            return res;
        },
        async activateSubs({ commit }, sub) {
            let url = `${_url}/activate/${sub.get('id')}`;
            const res = await axios.patch(url);
            await commit('activateSubs', res.data.data);
            return res;
        },
        async deleteSubs({ commit }, sub) {
            let url = `${_url}/remove/${sub.get('id')}`;
            const res = await axios.patch(url);
            console.log(res.data);
            await commit('deleteSubs', res.data.data);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        subs: state => state.subs,
        submeta: state => state.submeta,
    }
};