import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = 'tags';

export default {
    namespaced: true,
    state: {
        tags: null,
        tagmeta: null
    },
    mutations: {
        loadTags(state, tags) {
            state.tags = tags.data;
            state.tagmeta = tags.meta;
        },
        addTags(state, tag) {
            state.tags.unshift(tag);
        },
        updateTags(state, tag) {
            let index= state.tags.findIndex(t => t.key === tag.key);
            state.tags[index] = tag;
        },
        deleteTags(state, tag) {
            let index= state.tags.findIndex(t => t.key === tag);
            state.tags.splice(index, 1);
        }
    },
    actions: {
        async getTags({ commit },  url = _url) {

            const res = await axios.get(url);
            console.log(res.data.data);
            await commit('loadTags', res.data);
            return res;
        },
        async addTags({ commit }, Tag) {
            const res = await axios.post(_url, Tag);
            await commit('addTags', res.data.data);
            return res;
        },
        async updateTags({ commit }, Tag) {
            let url = `${_url}/${Tag.get('key')}`;
            const res = await axios.post(url, Tag);
            await commit('updateTags', res.data.data);
            return res;
        },
        async deleteTags({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteTags', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        tags: state => state.tags,
        tagmeta: state => state.tagmeta,
    }
};