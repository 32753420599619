<template>
  <!-- Main Wrapper -->
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum title="Support Tickets" />

        <div class="row">
          <div class="col-md-12 col-lg-9">
            <!-- Sales Chart -->
            <!--<Card cardClasses="card" :cardTitle="salesCard.title">
              <template v-slot:default>
                <div id="morrisArea"></div>
              </template>
            </Card>-->
            <!-- Recent Orders -->

            <Table :columns="tableColumns" v-if="tickets" :meta="meta" @getData="updateList">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in tickets" :key="index">
                  <td><span>
                    {{item['token']}}
                  </span></td>
                  <td><span>
                    {{item['priority']}}
                  </span></td>
                  <td><span>
                    {{item['subject']}}
                  </span></td>
                  <td><span>
                    {{item['department']}}
                  </span></td>
                  <td><span>
                    {{item['status']}}
                  </span></td>
                  <td><span>
                    {{item['submitted_by']}}
                  </span></td>
                  <td><span>
                    {{item['last_reply']}}
                  </span></td>
                  <!-- last column for adding modal buttons -->
                  <td>
                    <div class="actions">
                      <router-link :to="{ name: 'Comments', query: {ticketKey: item['key'] }}">
                        <a
                            data-toggle="modal"
                            href="javascript:0;"
                            class="btn btn-sm bg-success-light"
                        >
                          <i class="fe fe-pencil"></i>
                        </a>
                        </router-link>
                      <a
                        data-toggle="modal"
                        href="#deleteModal"
                        class="btn btn-sm bg-danger-light"
                        @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
            <!-- /Sales Chart -->
          </div>
          <div class="col-md-12 col-lg-3">
            <!-- Invoice Chart -->
            <Card cardClasses="card" :cardTitle="invoiceCard.title">
              <template v-slot:default>
                <p v-for="bodyDetail in invoiceCard.body" :key="bodyDetail">
                  <span class="nmbr">{{ bodyDetail.number }}</span>
                  {{ bodyDetail.description }}
                </p>
              </template>
              <template v-slot:footer>
                <p
                  v-for="footerDetail in invoiceCard.footer"
                  :key="footerDetail"
                >
                  <span class="nmbr">{{ footerDetail.number }}</span>
                  {{ footerDetail.description }}
                </p>
              </template>
            </Card>
            <!-- /Invoice Chart -->
          </div>
        </div>

        
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button type="submit" class="btn btn-primary mr-1 py-10">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import Form from "@/components/Form/Form";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";

const ticketTableColumns = [
  "ID",
  "Priority",
  "Subject",
  "Dept",
  "Status",
  "Started By",
  "Last Reply",
  "Action"
];

const invoiceCardBody ={
    open: {
      number: "0",
      description: "Open Ticket",
    },
    close: {
    number: "0",
    description: "Close",
  },
  wait: {
    number: "0",
    description: "Waiting",
  },
  replied: {
    number: "0",
    description: "Replied",
  },
};

const invoiceCardFooter ={
    high: {
      number: "0",
      description: "High Priority",
    },
    medium: {
      number: "0",
      description: "Medium Priority",
    },
    low: {
      number: "0",
      description: "Low Priority",
    },
  };
const endpoint  = '/tickets';

export default {
  name: "SupportTickets",
  components: {
    PageBreadcrum,
    Table,
    Card,
    Modal,
    Form,
  },
  data() {
    return {
      tableColumns: [...ticketTableColumns],
      salesCard: {
        title: "Ticket History",
      },
      invoiceCard: {
        title: "Ticket Overview",
        body: {...invoiceCardBody},
        footer: {...invoiceCardFooter},
      },
      selectedTicket: "",
      formUrl : endpoint,
      formAction : 'post',
      form: {
        name: "",
        key: "",
      },
      modal: true,
    };
  },
  methods: {

    ...mapActions("Ticket", [
      "getTickets",
      "addTickets",
      "updateTickets",
      "deleteTickets",
    ]),
    createOrUpdate: async function () {
      const User = new FormData();
      User.append("name", this.form.name);

      if (this.formAction == "post") {
        let res = await this.addTickets(User);
        return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        let res= await this.updateTickets(User);
        return res;
      }
    },
    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";

      if (this.formAction == "delete") {
        this.deleteTickets(this.form.key).then((res) => {
          this.modal= false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
              Swal.fire({
                title: "Error!",
                text: "Error occurred",
                icon: "error",
                confirmButtonText: "Cool",
              });
            });
      } else {
        this.createOrUpdate().then((res) => {
          this.modal= false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    sendInfo(item, formType) {
      if (item){
        this.formUrl = endpoint+'/'+item.key; // append key in  method
      } else {
        this.formUrl = endpoint;
      }
      this.formAction = formType;
      this.form = item;
      this.modal= true;
    },
    updateList(url) {
      this.getTickets(url)
    },
  },
  created() {
  },
  mounted() {
    this.getTickets();
  },
  watch: {
    meta(meta_val) {
      let tickt_ovrvu = meta_val.ticket_overview;
        this.invoiceCard.body.close.number = tickt_ovrvu.closed_ticket;
        this.invoiceCard.body.open.number = tickt_ovrvu.open_ticket;
        this.invoiceCard.body.replied.number = tickt_ovrvu.replied_ticket;
        this.invoiceCard.body.wait.number = tickt_ovrvu.wait_ticket;

        this.invoiceCard.footer.high.number = tickt_ovrvu.high_priority;
        this.invoiceCard.footer.low.number = tickt_ovrvu.low_priority;
        this.invoiceCard.footer.medium.number = tickt_ovrvu.medium_priority;
    }
  },
  computed: {
    ...mapGetters("Ticket", ["tickets", "meta"]),
  },
};
</script>

<style>
</style>