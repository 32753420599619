<template>
  <div>
    <!-- Sidebar -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="hr-sidebar sidebar-menu">
          <div class="widget-profile pro-widget-content">
            <div class="profile-info-widget">
              <!-- <a href="#" class="booking-doc-img">
                <img :src="setImg('assets/images/patient3.jpg')" alt="User Image" />
              </a>-->
              <!--<a href="javascript:void(0);" id="toggle_btn">
                <i class="fe fe-text-align-left"></i>
              </a>-->
              <!--<div class="profile-det-info">
                <h3>Admin</h3>
              </div> -->
            </div>
          </div>
          <ul class="hr-nav-links">
            <li :class="currentRouteName('HRDashboard')">
              <router-link :to="{ name: 'HRDashboard' }">
                <i class="fe fe-home"></i>
                <span>Dashboard</span>
              </router-link>
            </li>

            <li :class="currentRouteName('HREmployees')">
              <router-link :to="{ name: 'HREmployees' }">
                <i class="fe fe-users"></i> <span> Employees</span>
                <span class="menu-arrow"></span
              ></router-link>
            </li>

            <!-- to be replaced later with ul dropdown -->
            <li :class="currentRouteName('HRAppStatistics')">
              <router-link :to="{ name: 'HRAppStatistics' }">
                <i class="fa fa-pie-chart"></i> <span> App Statistics</span>
                <span class="menu-arrow"></span
              ></router-link>
            </li>

            <li :class="currentRouteName('HRSubscription')">
              <router-link :to="{ name: 'HRSubscription' }">
                <i class="fa fa-line-chart"></i> <span> Upgrade Plan</span>
                <span class="menu-arrow"></span
              ></router-link>
            </li>
          </ul>
          <ul class="sidebar-footer">
            <li :class="currentRouteName(['HRProfile', 'HRManagement'])">
                <a
                  href="#"
                  class="dropdown-toggle dropright custom-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-cog"></i> <span> Settings</span>
                  <span class="menu-arrow"></span
                ></a>
                <div class="dropdown-menu custom-dropdown-menu">
                   <router-link :to="{ name: 'HRProfile' }" class="dropdown-item">
                      Profile
                   </router-link>
                   <router-link :to="{ name: 'HRManagement' }" class="dropdown-item">
                      HR Management
                   </router-link>
                   <router-link :to="{ name: 'HRHelpSupport' }" class="dropdown-item">
                      Help & Support
                   </router-link>
                </div>
            </li>
            <li>
              <a href="#" @click="doLogout">
                <i class="fa fa-sign-out"></i> <span> Logout</span>
                <span class="menu-arrow"></span
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Sidebar -->
  </div>
</template>

<script>
import Export from "../../vue.config";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      app_url: Export.publicPath,
    };
  },
  name: "AdminSidebar",
  components: {},
  methods: {
    ...mapActions("Auth", [
      "logout",
    ]),
    setImg(meta) {
      let url = this.app_url;
      if (url.charAt(url.length - 1) == "/") url = url.slice(0, url.length - 1);
      return url + "/" + meta;
    },
    doLogout() {
      this.logout();
      this.$router.push({ name: 'HR' })
    }
  },
};
</script>

<style>
</style>
