<template>
  <!-- Main Wrapper -->
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
          title="Administrator Dashboard | Content Management"
          subTitle="Trainers"
        />

        <div class="row">
          <div
            class="col-sm-12 col"
            style="text-align: right; margin-bottom: 5px"
          >
            <button
              href="#trainerModal"
              data-toggle="modal"
              class="btn add"
              @click="sendInfo('', 'post')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>
            <span></span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table :columns="tableColumns" v-if="trainers" :meta="meta" @getData="updateList">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in trainers" :key="index">
                  <td v-for="column in tableColumns" :key="column">
                    <span
                      v-if="
                        column.replace(/\s/g, '_').toLowerCase() ==
                        'profile_pic'
                      "
                    >
                      <img
                        class="img-profile"
                        :src="item['profile_pic']"
                        alt=""
                      />
                    </span>
                    <span
                      v-else-if="
                        column.replace(/\s/g, '_').toLowerCase() ==
                        'description'
                      "
                    >
                      <a
                          data-toggle="modal"
                          href="#contentModal"
                          @click="setContent(item['description'])"
                      >
                      <i class="fa fa-eye"></i>
                    </a>
                    </span>
                    <span v-else>
                      {{ item[column.replace(/\s/g, "_").toLowerCase()] }}
                    </span>
                    <div   v-if="column == 'Action'" class="actions">
                      <a
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#trainerModal"
                          @click="sendInfo(item, 'put')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          data-toggle="modal"
                          href="#deleteModal"
                          class="btn btn-sm bg-danger-light"
                          @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                  <!-- last column for adding modal buttons -->
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Trainer Modal -->
  <Modal modalClasses="modal fade" modalId="trainerModal" :visible="modal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.key">Update Trainer</h5>
      <h5 class="modal-title" v-else>Add Trainer</h5>
    </template>
    <form
      :method="formAction"
      enctype="multipart/form-data"
      @submit.prevent="submitHandler"
    >
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Name of the Trainer"
              v-model="form.name"
              type="text"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-12 col-sm-12">
            <input
              class="form-control"
              type="text"
              placeholder="Designation"
              name="designation"
              v-model="form.designation"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <label for="profile_pic">
            Profile Pic
            <ImageUpload
              _name="profile_pic"
              id="profile_pic"
              :img="form.profile_pic"
              ref="img"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder="Description"
              name="description"
              v-model="form.description"
            ></textarea>
          </div>
        </div>
      </div>
      <button :disabled="in_progress" class="btn btn-primary btn-block">
        {{ form.key ? "Update" : "Add" }}
      </button>
    </form>
  </Modal>
  <!-- Trainer Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button type="submit" class="btn btn-primary mr-1 py-10" ref="submitModal">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
  <Modal modalClasses="modal fade" modalId="contentModal" :visible="modal">
    <div class="form-content p-2">
      <h4 class="modal-title">Description</h4>
      <hr>
      <span v-html="itemContent"></span>
    </div>
  </Modal>
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import ImageUpload from "@/components/Form/ImageUpload";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

const endpoint = "/trainer";

export default {
  name: "Trainers",
  components: {
    ImageUpload,
    PageBreadcrum,
    Table,
    Modal,
  },
  data() {
    return {
      tableColumns: ["Name", "Profile Pic", "Designation", "Description", "Action"],
      formUrl: endpoint,
      formAction: "post",
      form: {
        name: "",
        designation: "",
        description: "",
        profile_pic: "",
        key: "",
      },
      modal: true,
      in_progress: false,
      itemContent: null,
    };
  },
  methods: {
    ...mapActions("Trainer", [
      "getTrainers",
      "addTrainers",
      "updateTrainers",
      "deleteTrainers",
    ]),
     createOrUpdate: async function () {
      const User = new FormData();
      User.append("name", this.form.name);
      if(this.$refs.img.getFile()) User.append("profile_pic", this.$refs.img.getFile());
      User.append("designation", this.form.designation ? this.form.designation : '');
      User.append("description", this.form.description ? this.form.description : '');

      if (this.formAction == "post") {
        let res = await this.addTrainers(User);
        return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        let res= await this.updateTrainers(User);
        return res;  
      }
    },

    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";
     
      if (this.formAction == "delete") {
        this.deleteTrainers(this.form.key).then((res) => {
          //modal handling
          this.modal= false;
          if (typeof res.data != 'undefined') {
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          }
          })
          .catch((e) => {
            Swal.fire({
              title: "Error!",
              text: "No Trainer Found.",
              icon: "error",
              confirmButtonText: "Cool",
            });
          });
      } else {
        this.in_progress = true;
        this.createOrUpdate().then((res) => {
          this.in_progress = false;
          //modal handling
          this.modal= false;
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
            .catch((e) => {});
      }
    },
    getEndpoint() {
      axios
        .get(endpoint)
        .then((res) => (this.table.data = res.data.data))
        .catch((err) => console.log(err));
    },
    //for sending information of selected user to modal
    sendInfo(item, formType) {
      this.$refs.img.resetFile()
      // if (item) {
      //   this.formUrl = endpoint + "/" + item.key; // append key in  method
      // } else {
      //   this.formUrl = endpoint;
      // }
      this.formAction = formType;
      this.form = {...item};

      this.modal= true;
    },
    doTrim(string) {
      return string && string.length > 40
        ? string.split(" ").splice(0, 7).join(" ").concat("...")
        : string;
    },
    updateList(url) {
      this.getTrainers(url)
    },
    setContent(content) {
      this.itemContent = content;
    },
  },
  created() {

  },
  mounted() {
    this.getTrainers();
  },
  computed: {
    ...mapGetters("Trainer", ["trainers", "meta"]),
  },
};
</script>

<style>
.img-profile {
  max-width: 60px;
}
</style>
