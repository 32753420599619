export default {
    methods: {
        setImg(meta) {
            return "/" + meta;
          },
        dateFormatter(date, time = true) {
            let df = new Date(date);
            if (time)
                return `${df.toLocaleString()}`;
            else
                return `${df.toLocaleDateString()}`
        },
        localDateFormat(date) {
            let df = new Date(date);
            console.log(df.getMonth());
            var m = ("0"+(df.getMonth()+1)).slice(-2);
            var d = ("0"+df.getDate()).slice(-2);
            return `${df.getFullYear()}-${m}-${d}`;
        },
        currentRouteName(val, _class_name = 'active') {
            if(Array.isArray(val))
                return (val.indexOf(this.$route.name) > -1) ? _class_name : '';
            return (val == this.$route.name) ? _class_name : '';
        },
        errorHandler(error) {
            const  message = error. hasOwnProperty('message') ? error.message : 'Error!';
            var errors = '';
            if (error. hasOwnProperty('errors')) {
                for(const  _key in error.errors) {
                    errors += '<ul class="text-danger">';
                    if(Array.isArray(error.errors[_key])) {
                        error.errors[_key].map((i, j) => {
                            errors += `<li>${i}</li>`;
                        });
                    } else {
                        errors += `<li>${error.errors[_key]}</li>`;
                    }
                    errors += '</ul>';

                }
            }
            return [message, errors];
        },
        setPercentage(quantity, total) {
            if (! quantity) return quantity;
            return ((quantity/total)*100).toFixed(1);
        },
    },
    computed:{
        cr() {
            return this.$route.name;
        }
    }
    
};