import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "video";

export default {
    namespaced: true,
    state: {
        videos: null,
        meta_videos: null,
    },
    mutations: {
        loadVideos(state, videos) {
            state.videos = videos.data;
            state.meta_videos = videos.meta;
        },
        addVideos(state, video) {
            state.videos.unshift(video);
        },
        updateVideos(state, video) {
            let index= state.videos.findIndex(t => t.key === video.key);
            state.videos[index] = video;
        },
        deleteVideos(state, video) {
            let index= state.videos.findIndex(t => t.key === video);
            state.videos.splice(index, 1);
        }
    },
    actions: {
        async getVideos({ commit }, url) {

            const form = new FormData();
            form.append('is_hot_topic', 1);
            form.append('search_from[]', "Physically Happy");
            form.append('search_from[]', "Mentally Happy");
            let parameters = [...form.entries()]
                .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')
            const res = await axios.get(url ?? (_url+'?'+parameters))

            await commit('loadVideos', res.data);
            return res;
        },
        async addVideos({ commit }, Video) {
            const res = await axios.post(_url, Video);
            await commit('addVideos', res.data.data);
            return res;
        },
        async updateVideos({ commit }, Video) {
            let url = `${_url}/${Video.get('key')}`;
            const res = await axios.post(url, Video);
            console.log(res.data.data, 'update');
            await commit('updateVideos', res.data.data);
            return res;
        },
        async deleteVideos({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteVideos', key);
            return res;
        },



    },
    modules: {
    },
    getters: {
        videos: state => state.videos,
        meta_videos: state => state.meta_videos
    }
};