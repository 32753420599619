<template>
  <!-- Main Wrapper -->
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
            subTitle="Recipes"
            title="Administrator Dashboard | Content Management"
        />

        <div class="d-flex justify-content-end">
          <div
              class="form-inline"
              style="text-align: right; margin-bottom: 5px"
          >
            <div class="form-group mr-2">
              <input v-model="filterQuery" class="form-control" placeholder="Search by Name" type="text"/>
            </div>
            <button
                class="btn add text-right mr-2"
                @click="filterTable"
            >
              <i class="fa fa-filter"></i> Search
            </button>
            <button
                class="btn add"
                data-toggle="modal"
                href="#recipeModal"
                @click="sendInfo( '', 'post')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>
            <span></span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table v-if="recipes" :columns="tableColumns" :meta="meta_recipes" tableClasses="card-table"
                   @getData="updateList">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in recipes" :key="index">
                  <td><span>
                    <a data-toggle="modal"
                       href="#videoPlayer"
                       @click="playVideo(item['video']['video_name'])">
                       <img :src="item['video']['img']" style="width: 53px"/>
                      </a>
                  </span></td>
                  <td>
                    <span>
                      {{ item['video']['title'] }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item['video']['class_type']['name'] }}
                    </span>
                  </td>
                  <!--                  <td>-->
                  <!--                    <span>-->
                  <!--                      {{ item['video']['time'] }}-->
                  <!--                    </span>-->
                  <!--                  </td>-->
                  <td>
                    <a
                        data-toggle="modal"
                        href="#contentModal"
                        @click="setContent(item['content'])"
                    >
                      <i class="fa fa-eye"></i>
                    </a>
                  </td>
                  <td>
                    <span>
                        {{ item['meal'] }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item['calories'] }}
                    </span>
                  </td>
                  <td><span v-for="(ingredients, index) in item['ingredients']">{{ ingredients['name'] }} <template
                      v-if="index != (item['ingredients'].length - 1)">, </template> </span></td>
                  <td>
                    <div class="actions">
                      <a
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#recipeModal"
                          @click="sendInfo(item, 'patch')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          class="btn btn-sm bg-danger-light"
                          data-toggle="modal"
                          href="#deleteModal"
                          @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Recipe Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="recipeModal">
    <template v-slot:header>
      <h5 v-if="form.key" class="modal-title">Update Recipe</h5>
      <h5 v-else class="modal-title">Add Recipe</h5>
    </template>
    <form
        :method="formAction"
        @submit.prevent="submitHandler"
    >
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input v-model="form.video.title" class="form-control" placeholder="Name of the Recipe" required
                   type="text"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="sPCategory"
                :options="setRecipe.category"
                :required=true
                :searchable=true
                label="name"
                open-direction="bottom"
                placeholder="Select Category"
                track-by="key"
                @search-change="asyncCategories"
            />

          </div>
        </div>
        <!--        <div class="col-12 col-sm-6">-->
        <!--          <div class="form-group">-->
        <!--            <VueMultiselect-->
        <!--                value = 'array'-->
        <!--                :options="setRecipe.time"-->
        <!--                v-model="sPTime"-->
        <!--                :required=true-->
        <!--                @select="selectTime"-->
        <!--                placeholder="Select Duration"-->
        <!--            />-->
        <!--            <input type="hidden" name="time" :value="sPTime2" />-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <label for="video_file">
              Video
              <input
                  id="video_file"
                  accept=".mp4,.mov,.ogg,.x-flv"
                  class="form-control"
                  placeholder="Upload Video"
                  type="file"
                  @change="uploadVideo"
              />
            </label>
            <input :value="video_name" name="video_name" type="hidden"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <label for="img">
            Image
            <ImageUpload
                id="img"
                ref="img"
                :img="form.img"
                _name="img"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <ckeditor v-model="form.content" :config="editorConfig" :editor="editor"></ckeditor>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input v-model="form.calories" class="form-control" name="calories" placeholder="Calories" required
                   type="number"/>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <VueMultiselect
                v-model="sPMeal"
                :options="setRecipe.meal"
                :required=true
                label="name"
                placeholder="Select Meal"
                track-by="name"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="sPIngredients"
                :multiple="true"
                :options="setRecipe.ingredients"
                :required=true
                :searchable="true"
                label="name"
                open-direction="bottom"
                track-by="key"
                @search-change="asyncIngredients"
                placeholder="Select Ingredients"
            />
          </div>
        </div>
      </div>
<!--      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="sPTags"
                :multiple="true"
                :options="setRecipe.tags"
                :required=true
                :searchable="true"
                label="name"
                open-direction="bottom"
                track-by="key"
                @search-change="asyncTags"
                placeholder="Select tag"
            />
          </div>
        </div>
      </div>-->
      <button :disabled="in_progress" class="btn btn-primary btn-block" type="submit">
        {{ form.key ? 'Update' : 'Add' }}
      </button>
    </Form>
  </Modal>
  <!-- /Recipe Modal -->

  <!-- VideoPlayer Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="videoPlayer">
    <div v-html="videoPlayer"></div>
  </Modal>
  <!-- /Recipe Modal -->

  <!-- Delete Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="deleteModal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form
          :method="formAction"
          @submit.prevent="submitHandler"
      >
        <button class="btn btn-primary mr-1 py-10" type="submit">Yes</button>
        <button class="btn btn-danger" data-dismiss="modal" type="button">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
  <!-- Content Modal -->
  <Modal :visible="modal" modal-size="modal-xl" modalClasses="modal fade" modalId="contentModal">
    <div class="form-content p-2">
      <h4 class="modal-title">Recipe</h4>
      <hr>
      <span v-html="itemContent"></span>
    </div>
  </Modal>
  <!-- /Content Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import VueMultiselect from 'vue-multiselect';

import Export from "../../../vue.config";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import ImageUpload from "@/components/Form/ImageUpload";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const recipeTableColumns = [
  "Video",
  "Name",
  "Category",
  // "Duration",
  "Recipe",
  "Meal",
  "Calories",
  "Ingredients",
  "Action"
];
const endpoint = "/recipe";
const categoryEndpoint = 'category';
const ingredientEndpoint = 'ingredient';
// const timeEndpoint = 'duration-by-param';
// const tagEndpoint = 'tags';
const mealEndpoint = 'get-meals';
const uploadEndpoint = 'admin/vimeo';
const videoPlayerEndpoint = 'https://player.vimeo.com';

export default {
  name: "Recipes",
  components: {
    PageBreadcrum,
    Table,
    Modal,
    VueMultiselect,
    ImageUpload
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        placeholder: 'Recipe',
      }
      ,
      sPCategory: {},
      // sPTime: null,
      sPTags: [],
      sPMeal: null,
      sPIngredients: [],
      // sPTime2: null,
      tableColumns: [...recipeTableColumns],
      setRecipe: {
        category: [],
        // time : [],
        tags: [],
        ingredients: [],
        meal: [],
      },
      //selected user for modal

      formUrl: endpoint,
      formAction: 'post',
      in_progress: false,
      video_name: String,
      videoPlayer: null,
      app_url: Export.publicPath,
      pagination: {
        category: categoryEndpoint + '?list_from=Nutritionally Happy',
      },
      form: {
        key: null,
        content: "",
        img: null,
        meal: null,
        calories: null,
        video: {
          title: null
        },
        trainer: {},
        ingredients: {},
      },
      itemContent: null,
      modal: true,
      filterQuery: "",
    };
  },
  methods: {
    ...mapActions("Recipe", [
      "getRecipes",
      "addRecipes",
      "updateRecipes",
      "deleteRecipes",
    ]),
    createOrUpdate: async function () {
      const Recipe = new FormData();
      Recipe.append("title", this.form.video.title);
      Recipe.append("video_name", this.video_name);
      Recipe.append("img", this.$refs.img.getFile());
      Recipe.append("content", this.$data.form.content);
      Recipe.append("calories", this.form.calories);
      Recipe.append("class_type", this.sPCategory.key);
      // Recipe.append("time", this.sPTime2);
      Recipe.append("meal", this.sPMeal.name);
      this.sPIngredients.forEach((i, j) => {
        Recipe.append("ingredients[]", i.key);
      });
      // this.sPTags.forEach((i, j) => {
      //   Recipe.append("tags[]", i.key);
      // });


      if (this.formAction == "post") {
        let res = await this.addRecipes(Recipe);
        return res;
      } else if (this.formAction == "patch") {
        Recipe.append("key", this.form.key);
        Recipe.append("_method", "PATCH");
        let res = await this.updateRecipes(Recipe);
        return res;
      }
    },
    submitHandler(e) {

      if (this.formAction == "delete") {
        this.deleteRecipes(this.form.key).then((res) => {
          this.modal = false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
              Swal.fire({
                title: "Error!",
                text: "Error occurred",
                icon: "error",
                confirmButtonText: "Cool",
              });
            });
      } else {
        this.in_progress = true;
        this.createOrUpdate().then((res) => {
          this.in_progress = false;
          this.modal = false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    sendInfo(item, formType) {
      this.form.key = null;
      this.video_name = '';
      this.$refs.img.resetFile();
      if (item) {
        this.video_name = item.video.video_name;
        this.form.key = item.key;
        this.form.video = {...item.video};
        this.formUrl = endpoint + '/' + item.key; // append key in  method
      } else {
        this.form.video = {
          title: null,
        }
        this.formUrl = endpoint;
      }
      this.formAction = formType;
      this.form.content = item.content ? item.content : '';
      this.form.calories = item.calories;

      if (formType == 'put' || formType == 'patch') {
        // this.sPTime2 = item.video.time;
        // if (item.video.time == '10 Min') {
        //   this.sPTime = 1;
        // } else if (item.video.time == '20 Min') {
        //   this.sPTime = 2;
        // } else {
        //   this.sPTime = 3;
        // }
        this.sPIngredients = [...item.ingredients];
        // this.sPTags = [...item.video.tags];
        this.sPCategory = {...item.video.class_type};
        this.sPMeal = {name: item.meal, key: item.key}; // giving dummy key


        this.setRecipe.ingredients = item.ingredients;
        this.setRecipe.tags = item.video.tags;

        this.checkSelectedSet('category');

      } else {
        this.sPCategory = null;
        this.sPTrainer = null;
        // this.sPTime    = null;
        this.sPTags = [];
        this.sPMeal = null;
        this.sPIngredients = [];
      }
      this.modal = true;
    },
    getExtraData(url, type, extraParam = null) {
      axios.get(url)
          .then(res => {
            if (extraParam && extraParam.append)
              this.setRecipe[type] = [...this.setRecipe[type], ...res.data.data];
            else
              this.setRecipe[type] = res.data.data;
            if (typeof res.data.links != 'undefined') this.pagination[type] = (res.data.links.next);
            else this.pagination[type] = null;
            if (extraParam && extraParam.searchMore) {
              this.checkSelectedSet(type);
            }
          })
          .catch(err => console.log(err));
    },
    uploadVideo(event) {
      this.in_progress = true;
      let data = new FormData();
      data.append('video', event.target.files[0])
      axios.post(uploadEndpoint, data)
          .then(res => {
            this.video_name = res.data.data.video_path;
            return axios.put(uploadEndpoint, {
              video_uri: res.data.data.video_path
            })
          })
          .then(res => {
            this.in_progress = false
          })
          .catch(err => console.log(err));
    },
    playVideo(video) {
      let _video = video.replace('videos', 'video');
      this.videoPlayer = `
      <iframe src="${videoPlayerEndpoint}${_video}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen width="100%" height="100%"></iframe>
      `;
    },
    setImg(meta) {
      let url = this.app_url;
      if (url.charAt(url.length - 1) == '/') // it will check n remove /
        url = url.slice(0, url.length - 1);
      return `${url}/${meta}`;
    },
    // selectTime(data, TimeProp) {
    //   this.sPTime2 = TimeProp.label;
    // },
    checkSelectedSet(type) {
      var key = this[type];
      if (type == 'category')
        var key = this.form.video.class_type.key;

      if (this.setRecipe[type].findIndex(_data => _data.key == key) == -1) {
        if (this.pagination[type]) {
          this.getExtraData(`${this.pagination[type]}`, type, {
            append: true,
            searchMore: true
          });
        }
      }
    },
    setContent(content) {
      this.itemContent = content;
    },
    asyncIngredients(query) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        this.getExtraData(`${ingredientEndpoint}?query=${query}`, 'ingredients');
      }, 750);
    },
    asyncCategories(query) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        this.getExtraData(categoryEndpoint + "?list_from=Nutritionally Happy&query=" + query, 'category');
      }, 750);
    },
    /*asyncTags(query) {
      if (typeof window.ajaxController != 'undefined') clearTimeout(window.ajaxController); // handle server requests
      window.ajaxController = setTimeout(() => {
        this.getExtraData(`${tagEndpoint}?&query=${query}`, 'tags');
      }, 750);
    },*/
    updateList(url) {
      this.getRecipes(url)
    },
    filterTable() {
      this.getRecipes(`recipe?query=${this.filterQuery}`);
    },
  },
  created() {
    // this.getExtraData(timeEndpoint+'?all_duration=1', 'time');
    this.getExtraData(categoryEndpoint + '?list_from=Nutritionally Happy', 'category');
    // this.getExtraData(tagEndpoint, 'tags');
    // this.getExtraData(ingredientEndpoint, 'ingredients');
    this.getExtraData(`${mealEndpoint}?all_meal=1`, 'meal');
  },
  mounted() {
    this.getRecipes();
  },
  computed: {
    ...mapGetters("Recipe", ["recipes", "meta_recipes"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>