import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "admin/corporate-plans";

export default {
    namespaced: true,
    state: {
        plans: null,
        meta: null
    },
    mutations: {
        loadPlans(state, plans) {
            state.plans = plans.data;
            state.meta = plans.meta;
        },
        addPlans(state, plan) {
            state.plans.unshift(plan);
        },
        updatePlans(state, plan) {
            let index= state.plans.findIndex(t => t.key === plan.key);
            state.plans[index] = plan;
        },
        deletePlans(state, plan) {
            let index= state.plans.findIndex(t => t.key === plan);
            state.plans.splice(index, 1);
        }
    },
    actions: {
        async getPlans({ commit }, url = _url) {

            const res = await axios.get(url);
            console.log(res.data.data);
            await commit('loadPlans', res.data);
            return res;
        },
        async addPlans({ commit }, Hr) {
            const res = await axios.post(_url, Hr);
            await commit('addPlans', res.data.data);
            return res;
        },
        async updatePlans({ commit }, Hr) {
            let url = `${_url}/${Hr.get('key')}`;
            const res = await axios.post(url, Hr);
            await commit('updatePlans', res.data.data);
            return res;
        },
        async deletePlans({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            if (typeof res.data != 'undefined')
                await commit('deletePlans', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        plans: state => state.plans,
        meta: state => state.meta
    }
};