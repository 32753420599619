<template>
  <div>
    <HrHeader pageHeading="Subscription" pageSubHeading="Upgrade Plan" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">
            <Table
              tableClasses="card-table hr-table"
              :columns="table.columns"
              :meta="meta"
              @getData="updateList"
            >
              <template v-for="(item, index) in plans" :key="index">
                <!-- making td accoding to number of columns -->
                <tr>
                  <td v-for="column in table.columns" :key="column">
                    <span
                      v-if="column == 'Status' && item['active'] == 'Active'"
                      class="green"
                    >
                      {{ mutateColumnName(item, column) }}</span
                    >
                    <span v-else> {{ mutateColumnName(item, column) }}</span>

                    <!-- if status is active then set color to green -->
                    <!-- <span v-if="column == 'Status' && item['status'] == 'Active'" class="green">
                       {{ item[column.replace(/\s/g, "").toLowerCase()] }}
                    </span> -->

                    <!-- if status is Inactive then set color to red -->
                    <!-- <span v-else-if="column == 'Status' && item['status'] == 'Inactive'" class="red">
                      {{ item[column.replace(/\s/g, "").toLowerCase()] }}
                    </span> -->

                    <!-- if column is details column then display collapsable button -->
                    <!-- <span v-else-if="column == 'Details'">
                       <button type="button" class="btn blue" data-toggle="collapse" :data-target="'#subscriptionDetails_'+index">
                          Show Details <i class="fa fa-sort-down position-relative top-minus2 ml-1"></i>
                        </button>
                      {{assignSubscptionDetails(item[column.replace(/\s/g, "").toLowerCase()])}}-->
                    <!-- </span> -->

                    <!-- <span v-else>
                      {{ item[column.replace(/\s/g, "").toLowerCase()] }}
                    </span> -->

                    <div
                      v-if="column == 'Action' && item['active'] != 'Active'"
                      class="actions"
                    >
                      <a
                        data-toggle="modal"
                        href="#subscriptionModal"
                        class="btn btn-sm bg-info-light"
                        @click="sendInfo(item)"
                      >
                        Upgrade
                      </a>
                    </div>
                  </td>
                </tr>
                <!--                <tr :id="'subscriptionDetails_'+index" class="collapse out">
                   <td colspan="6" class="text-left colspan-td">
                      <p>Details</p>
                      <ul>
                         <li v-for="list in item.details" :key="list" >
                            {{list}}
                         </li>
                      </ul>
                   </td>
                </tr>-->
              </template>
            </Table>
          </div>

          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="subscriptionModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Upgrade</h4>
      <p class="mb-4">Do you want to upgrade plan?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button type="submit" class="btn btn-primary mr-1 py-10">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";

import axios from "axios";
import Swal from "sweetalert2";
const employeesTableColumns = [
  "Plan",
  "Max Users",
  "Details",
  "Status",
  "Action",
];
const employeesTableData = [
  {
    plan: "2Hr",
    price: "$500",
    startdate: "21-05-2021",
    details: [
      "Loreum Ipsum 1",
      "Loreum Ipsum 2",
      "Loreum Ipsum 3",
      "Loreum Ipsum 4",
    ],
    status: "Active",
  },
  {
    plan: "3Hr",
    price: "$500",
    startdate: "21-05-2021",
    details: [
      "Loreum Ipsum 5",
      "Loreum Ipsum 6",
      "Loreum Ipsum 7",
      "Loreum Ipsum 8",
    ],
    status: "Inactive",
  },
  {
    plan: "5Hr",
    price: "$500",
    startdate: "21-05-2021",
    details: [
      "Loreum Ipsum 9",
      "Loreum Ipsum 1",
      "Loreum Ipsum 3",
      "Loreum Ipsum 5",
    ],
    status: "Inactive",
  },
];

export default {
  name: "Subscription",
  components: {
    Card,
    Support,
    Table,
    Modal,
    HrHeader,
    HrSidebar,
  },
  data() {
    return {
      table: {
        columns: [...employeesTableColumns],
        data: [...employeesTableData],
      },
      //selected user for modal
      selectedPlan: "",
      subscriptionDetails: "",
      plans: null,
      modal: false,
    };
  },
  methods: {
    //for sending information of selected user to modal
    sendInfo(item) {
      this.selectedPlan = item;
      this.modal= true;
    },
    assignSubscptionDetails(values) {
      this.subscriptionDetails = values;
    },
    fetchPlans() {
      axios.get("/hr/plans").then((data) => {
        let d = data.data.data;
        d.map((plan) => {
          if (plan.hasOwnProperty("active")) {
            plan.active = "Active";
          } else {
            plan.active = "Inactive";
          }
        });
        this.plans = d;
      });
    },
    /**
     * Use it only for the meta values
     */
    mutateColumnName(item, column) {
      if (column === "Plan") {
        return item.name;
      } else if (column === "Plan") {
        return item.name;
      } else if (column === "Max Users") {
        return item.max_users;
      } else if (column === "Details") {
        return item.meta.description;
      } else if (column === "Status") {
        return item.active;
      }

      // if (column === "Max Users") {
      //   return item.max_users;
      // }
      // if (column === "Status") {
      //   return Boolean(item.status) ? "Active" : "Inactive";
      // }
      // if (meta.includes(column)) {
      //   return item.meta[column.replace(/\s/g, "").toLowerCase()];
      // }

      return item[column.replace(/\s/g, "").toLowerCase()];
    },
    submitHandler() {
      axios
        .post("hr/upgrade-plan", {
          plan: this.selectedPlan.key,
        })
        .then((res) => {
        this.modal= false;
          Swal.fire({
            title: "Success!",
            text: res.data.data,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
        .catch((e) => {});
    },
  },
  mounted() {
    this.fetchPlans();
  },
};
</script>

<style>
</style>