<template>
  <div class="custom-element">
    <h1>This is our trainers page</h1>
  </div>
</template>

<script>

export default {
  name: "OurTrainer",
  components: {
  },
};
</script>

<style>
</style>>
