<template>
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-end" v-if="meta">
        <li v-for="links in meta.links" :class="isActive(links.active, links.url)">
          <a href="javascript:;" class="page-link" @click="getURLEndpoint(links.url)" v-html="links.label"></a>
        </li>
    </ul>
  </nav>
</template>

<script>
export default {
name: "Paginate",
props: {
  meta: [Object],
},
  methods: {
    isActive(active, url) {
      var _class = "page-item";
      if (active) _class += " active";
      if (! url) _class += " disabled";
      return _class;
    },
    getURLEndpoint(url) {
      this.$emit("getData", url)
    },
  }
}
</script>

<style scoped>

</style>