<template>
  <div>
    <HrHeader pageHeading="Help & Support" pageSubHeading="Settings"/>
    <HrSidebar/>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 ">

            <div class="row">
              <div class="col-md-12 text-right">
                <button
                    class="btn add text-right"
                    data-toggle="modal"
                    href="#ticketsModal"
                    @click="sendInfo"
                >
                  <i class="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
            <!-- Recent Orders -->

            <Table v-if="tickets" :columns="tableColumns" :meta="meta"
                   tableClasses="card-table hr-table"
                   @getData="updateList"
            >
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in tickets" :key="index">
                  <td><span>
                    {{ item['token'] }}
                  </span></td>
                  <td><span>
                    {{ item['priority'] }}
                  </span></td>
                  <td><span>
                    {{ item['subject'] }}
                  </span></td>
                  <td><span>
                    {{ item['department'] }}
                  </span></td>
                  <td><span>
                    {{ item['status'] }}
                  </span></td>
                  <td><span>
                    {{ item['submitted_by'] }}
                  </span></td>
                  <td><span>
                    {{ item['last_reply'] }}
                  </span></td>
                  <!-- last column for adding modal buttons -->
                  <td>
                    <div class="actions">
                      <router-link :to="{ name: 'HRSupport', query: {ticketKey: item['key']} }"
                                   class="btn btn-sm bg-success-light">
                        <i class="fa fa-paper-plane"></i>
                      </router-link>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
        <div class="row d-flex justify-content-center">
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <Modal :visible="modal" modalClasses="modal fade" modalId="ticketsModal">
    <template v-slot:header>
      <h5 class="modal-title">Add Ticket</h5>
    </template>
    <form @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
                v-model="this.form.subject"
                class="form-control"
                placeholder="Subject"
                required
                type="text"
            />
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
                    <textarea
                        v-model="this.form.description"
                        class="form-control"
                        placeholder="Description"
                    />
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="dept"
                :options="setTicket.dept"
                :required=true
                :searchable="false"
                open-direction="bottom"
                placeholder="Select Department"
            />
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <ImageUpload
                id="attachment"
                ref="img"
                name="attachment"
            />
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block" type="submit">Submit</button>
    </form>
  </Modal>
</template>

<script>
import Card from "@/components/Card.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import axios from "axios";
import VueMultiselect from 'vue-multiselect';
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import ImageUpload from "@/components/Form/ImageUpload";
import Table from "@/components/Table";
import Modal from "@/components/Modal";

const ticketTableColumns = [
  "ID",
  "Priority",
  "Subject",
  "Dept",
  "Status",
  "Started By",
  "Last Reply",
  "Action"
];

export default {
  name: "HelpSupport",
  components: {
    Table,
    Card,
    HrHeader,
    HrSidebar,
    VueMultiselect,
    ImageUpload,
    Modal
  },
  data() {
    return {
      tableColumns: [...ticketTableColumns],
      setTicket: {
        dept: [],
      },
      dept: null,
      form: {},
      img: null,
      modal: true,
    };
  },
  methods: {
    ...mapActions("Ticket", [
      "addTickets",
      "getTickets"
    ]),
    submitHandler() {
      this.modal = false;
      let Form = new FormData();
      Form.append('subject', this.form.subject);
      if (this.form.description) Form.append('description', this.form.description);
      Form.append('department', this.dept);
      if (this.$refs.img.getFile()) Form.append('attachment', this.$refs.img.getFile());

      this.addTickets(Form)
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
            this.resetForm();
          })
          .catch((e) => {
          });
    },
    getDept() {
      axios.get('/tickets/dept')
          .then(res => this.setTicket.dept = res.data.data)
    },
    resetForm() {
      this.form = {};
      this.$refs.img.resetFile()
      this.dept = null;
    },
    sendInfo() {
      this.modal = true;
    },
    updateList(url) {
      this.getTickets(url)
    },
  },
  created() {
    this.getDept();
  },
  mounted() {
    this.getTickets();
  },
  computed: {
    ...mapGetters("Ticket", ["tickets", "meta"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>