import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _user_url = "admin/company";

export default {
    namespaced: true,
    state: {
        corpuser: null,
        corpmeta: null,
    },
    mutations: {
        loadCorpUsers(state, corpuser) {
            state.corpuser = corpuser.data;
            state.corpmeta = corpuser.meta;
        },
        /*addCorpUsers(state, user) {
            state.corpuser.unshift(user);
        },*/
        // updateCorpUsers(state, user) {
        //     let company_index= state.corpuser.findIndex(t => t.code === user.hr.company_id);
        //     console.log(company_index);
        //     let index = state.corpuser[company_index].hrs.findIndex(t => t.key === user.key);
        //
        //     state.corpuser[company_index].hrs[index] = user;
        // },
        /*deleteCorpUsers(state, user) {
            let index= state.corpuser.findIndex(t => t.key === user);
            state.corpuser.splice(index, 1);
        }*/
    },
    actions: {
        async getCorpUsers({ commit }, url = `${_user_url}`) {
            const res = await axios.get(url);
            await commit('loadCorpUsers', res.data);
            return res;
        },
        /*async addCorpUsers({ commit }, CorpUser) {
            const res = await axios.post(_user_url, CorpUser);
            await commit('addCorpUsers', res.data.data);
            return res;
        },*/
        async updateCorpUsers({ commit }, CorpUser) {
            let url = `users/${CorpUser.get('key')}`;
            const res = await axios.post(url, CorpUser);
            await commit('updateCorpUsers', res.data.data);
            return res;
        },
        /*async deleteCorpUsers({ commit }, key) {
            let url = `${_user_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteCorpUsers', key);
            return res;
        },*/
    },
    modules: {
    },
    getters: {
        corpuser: state => state.corpuser,
        corpmeta: state => state.corpmeta,
    }
};