import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "trainer";

export default {
    namespaced: true,
    state: {
        trainers: null,
        meta: null,
    },
    mutations: {
        loadTrainers(state, trainers) {
            var _sortd_trnr = [];
            for (var trns in trainers.data) {
                _sortd_trnr.push(trainers.data[trns]);
            }
            _sortd_trnr.sort((a, b)=>{
                var rebca = a.name.toString().match(/rebecca/gi);
                if (rebca && rebca.length)
                    return -1;
                return 1;
            });
            state.trainers = _sortd_trnr;
            state.meta = trainers.meta;
        },
        addTrainers(state, trainer) {
            state.trainers.unshift(trainer);
        },
        updateTrainers(state, trainer) {
            let index= state.trainers.findIndex(t => t.key === trainer.key);
            state.trainers[index] = trainer;
        },
        deleteTrainers(state, trainer) {
            let index= state.trainers.findIndex(t => t.key === trainer);
            state.trainers.splice(index, 1);
        }
    },
    actions: {
        async getTrainers({ commit }, url = null) {
            const res = await axios.get( url ?? _url);
            console.log(res.data);
            await commit('loadTrainers', res.data);
            return res;
        },
        async addTrainers({ commit }, Trainer) {
            const res = await axios.post(_url, Trainer);
            await commit('addTrainers', res.data.data);
            return res;
        },
        async updateTrainers({ commit }, Trainer) {
            let url = `${_url}/${Trainer.get('key')}`;
            const res = await axios.post(url, Trainer);
            await commit('updateTrainers', res.data.data);
            return res;
        },
        async deleteTrainers({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            if (typeof res.data != 'undefined')
                await commit('deleteTrainers', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        trainers: state => state.trainers,
        meta: state => state.meta,
    }
};