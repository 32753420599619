<template>
  <div :class="cardClasses">
    <div class="card-header header-header" v-if="cardTitle || $slots.header">
      <slot v-if="$slots.header" name="header"></slot>
      <h4 v-else class="card-title">{{ cardTitle }}</h4>
    </div>
    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="card-footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    cardClasses: [String, Object, Array],
    cardTitle: String,
  },
};
</script>

<style>
</style>