<template>
  <div>
    <HrHeader pageHeading="Videos" pageSubHeading="All Video" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">
            <Table tableClasses="card-table hr-table" :columns="table.columns">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in table.data" :key="index">
                  <td v-for="column in table.columns" :key="column">
                    <img
                      v-if="column == 'Video'"
                      :src="item[column.replace(/\s/g, '').toLowerCase()]"
                    />
                    <span v-else>
                      {{ item[column.replace(/\s/g, "").toLowerCase()] }}</span
                    >
                  </td>
                </tr>
              </template>
            </Table>
          </div>

          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import Table from "@/components/Table.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";

const videosTableColumns = ["Video Name", "Category", "Section", 'Views', "Description", "Video"];
const videosTableData = [
  {
    videoname: "HIIT Classes For Stretching",
    category: "HIIT",
    section: "Physically",
    views: '25',
    description: "Loreum Ipsum dolor sit amet,",
    video: "/assets/images/video.jpg",
  },
  {
    videoname: "HIIT Classes For Stretching",
    category: "HIIT",
    section: "Physically",
    views: '25',
    description: "Loreum Ipsum dolor sit amet,",
    video: "/assets/images/video.jpg",
  },
  {
    videoname: "HIIT Classes For Stretching",
    category: "HIIT",
    section: "Physically",
    views: '25',
    description: "Loreum Ipsum dolor sit amet,",
    video: "/assets/images/video.jpg",
  },
  {
    videoname: "HIIT Classes For Stretching",
    category: "HIIT",
    section: "Physically",
    views: '25',
    description: "Loreum Ipsum dolor sit amet,",
    video: "/assets/images/video.jpg",
  },
];

export default {
  name: "Videos",
  components: {
    Card,
    Support,
    Table,
    HrHeader,
    HrSidebar,
  },
  data() {
    return {
      table: {
        columns: [...videosTableColumns],
        data: [...videosTableData],
      },
    };
  },
};
</script>

<style>
</style>