<template>
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
          title="Administrator Dashboard | Corporate Plan Management"
        />

        <div class="row">
          <div
            class="col-sm-12 col"
            style="text-align: right; margin-bottom: 5px"
          >
            <button
              href="#planModal"
              data-toggle="modal"
              class="btn add"
              @click="sendInfo(false, 'post')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>
            <span></span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table tableClasses="card-table" :columns="table.columns"  :meta="meta" @getData="updateList">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in plans" :key="index">
                  <td v-for="column in table.columns" :key="column">
                    <span> {{ mutateColumnName(item, column) }}</span>
                    <div v-if="column == 'Action'" class="actions">
                      <a
                        class="btn btn-sm bg-success-light"
                        data-toggle="modal"
                        href="#planModal"
                        @click="sendInfo(item, 'put')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                        data-toggle="modal"
                        href="#deleteModal"
                        class="btn btn-sm bg-danger-light"
                        @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Plan Modal -->
  <Modal modalClasses="modal fade" modalId="planModal" :visible="modal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.key">Update Plan</h5>
      <h5 class="modal-title" v-else>Add Plan</h5>
    </template>
    <form :method="formAction" @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Plan Name"
              required
            />
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <select class="form-control" v-model="form.max_users">
              <option value="">Max Users</option>
              <template v-for="counts in countHandler()">
                <option :value="counts">{{counts}}</option>
              </template>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <select class="form-control" v-model="form.status">
              <option value="">Status</option>
              <option value="true">Active</option>
              <option value="false">InActive</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
              v-model="form.price"
              type="text"
              class="form-control"
              placeholder="Cost"
              required
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <select class="form-control" v-model="form.validity" required>
              <option value="">Validity</option>
                <template v-for="validity in planValidity()">
                <option :value="validity">{{validity}} Days</option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-12">
          <div class="form-group">
            <textarea
                v-model="form.description"
                class="form-control"
                placeholder="Description"
                required
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">{{form.key ? 'Update' : 'Add'}}</button>
    </form>
  </Modal>
  <!-- Plan Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button type="submit" class="btn btn-primary mr-1 py-10">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

const tableColumns = [
  "Name",
  "Description",
  "Max Users",
  "Price",
  "Validity",
  "Status",
  "Action",
];
const tableData = [
  {
    plan: "2Hr",
    type: "Corporate",
    cost: "$500",
    validity: "30 Days",
    status: "Active",
  },
  {
    plan: "2Hr",
    type: "Corporate",
    cost: "$500",
    validity: "30 Days",
    status: "Active",
  },
  {
    plan: "2Hr",
    type: "Corporate",
    cost: "$500",
    validity: "30 Days",
    status: "Active",
  },
];

export default {
  name: "PlanManagement",
  components: {
    PageBreadcrum,
    Table,
    Modal,
  },
  data() {
    return {
      table: {
        columns: [...tableColumns],
        data: [...tableData],
      },
      form: {
        name: "",
        key: "",
        max_users: "",
        price: "",
        status: "",
        description: "",
        validity: "",
      },
      //selected user for modal
      selectedUser: "",
      formAction:"post",
      modal: false,
    };
  },
  methods: {
    ...mapActions("CorporatePlan", [
      "getPlans",
      "addPlans",
      "updatePlans",
      "deletePlans",
    ]),
    getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach((key) => formData.append(key, object[key]));
      return formData;
    },

    //for sending information of selected user to modal
    sendInfo(item, formType) {
      this.modal= true;
      this.formAction = formType;
      if (!item) {
        for (let [key, value] of Object.entries(this.form)) {
          this.form[key] = "";
          // console.log(`${key}: ${value}`);
        }
      } else {
        this.form.name = item.name;
        this.form.key = item.key;
        this.form.max_users = item.max_users;
        this.form.status = item.status;
        this.form.description = item.meta.description;
        this.form.price = item.meta.price;
        this.form.validity = item.validity;
      }
    },
    createOrUpdate: async function () {
      const User = this.getFormData(this.form);

      if (this.formAction == "post") {
        let res = await this.addPlans(User);
        return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        let res = await this.updatePlans(User);
        return res;
      }
    },
    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";

      if (this.formAction == "delete") {
        this.deletePlans(this.form.key)
          .then((res) => {
            this.modal= false;
            if (typeof res.data != 'undefined') {
              Swal.fire({
                title: "Success!",
                text: `Operation Successful!`,
                icon: "success",
                confirmButtonText: "Cool",
              });
            }
          })
          .catch((e) => {
          });
      } else {
        this.createOrUpdate()
          .then((res) => {
            this.modal= false;
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
            .catch((e) => {});
      }
    },

    /**
     * Use it only for the meta values
     */
    mutateColumnName(item, column) {
      let meta = ["Description", "Price"];
      if (column === "Max Users") {
        return item.max_users;
      }
      if (column === "Status") {
        return Boolean(item.status) ? "Active" : "Inactive";
      }
      if (column === "Validity") {
        if (item.validity) return item.validity + " Days";
      }
      if (meta.includes(column)) {
        return item.meta[column.replace(/\s/g, "").toLowerCase()];
      }

      return item[column.replace(/\s/g, "").toLowerCase()];
    },
    planValidity() {
      return {
        30 : 30,
        90 : 90,
        180 : 180,
        270 : 270,
        365 : 365,
      };
    },
    countHandler() {
      return {
        30 : 30,
        75 : 75,
        100 : 100,
        250 : 250,
        500 : 500,
        1000 : 1000,
      };
    },
    updateList(url) {
      this.getPlans(url+"?all_plans=true");
    },
  },
  mounted() {
    this.getPlans("/admin/corporate-plans?all_plans=true");
  },
  computed: {
    ...mapGetters("CorporatePlan", ["plans", "meta"]),
  },
};
</script>

<style>
</style>