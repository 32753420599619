<template>
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
            subTitle="Categories"
            title="Administrator Dashboard | Content Management"
        />

        <div class="row">
          <div
              class="col-sm-12 col"
              style="text-align: right; margin-bottom: 5px"
          >
            <button
                class="btn add"
                data-toggle="modal"
                href="#categoryModal"
                @click="sendInfo('', 'post', 'cat')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>

          </div>
        </div>

        <div class="row" style="margin-bottom: 40px">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table v-if="categories" :columns="tableColumns" :meta="meta_categories" tableClasses="card-table"
                   @getData="updateList">
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in categories" :key="index">
                  <td>
                    <span>
                    {{ item['name'] }}
                      </span>
                  </td>
                  <td>
                    <span>
                    {{ item['parent']['name'] }}
                      </span>
                  </td>
                  <td>
                    <span>
                    {{ item['total_sub_cat'] }}
                      </span>
                  </td>
                  <td>
                    <span>
                    {{ item['total_video'] }}
                      </span>
                  </td>
                  <td>
                    <div class="actions">
                      <a
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#categoryModal"
                          @click="sendInfo(item, 'put', 'cat')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          class="btn btn-sm bg-danger-light"
                          data-toggle="modal"
                          href="#deleteModal"
                          @click="sendInfo(item, 'delete', 'cat')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>

        <PageBreadcrum subTitle="Sub Categories"/>

        <div class="row">
          <div
              class="col-sm-12 col"
              style="text-align: right; margin-bottom: 5px"
          >
            <button
                class="btn add"
                data-toggle="modal"
                href="#subCategoryModal"
                @click="sendInfo('', 'post', 'sub_cat')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table v-if="sub_categories"
                   :columns="subCategoryTable" :meta="meta_sub_categories"
                   tableClasses="card-table" @getData="updateSubList"
            >
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in sub_categories" :key="index">
                  <td>
                    <span>
                      {{ item['name'] }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item['super_parent'] }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item['parent']['name'] }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item['total_video'] }}
                    </span>
                  </td>
                  <td>
                    <div class="actions">
                      <a
                          class="btn btn-sm bg-success-light"
                          data-toggle="modal"
                          href="#subCategoryModal"
                          @click="sendInfo(item, 'put', 'sub_cat')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <a
                          class="btn btn-sm bg-danger-light"
                          data-toggle="modal"
                          href="#deleteModal"
                          @click="sendInfo(item, 'delete', 'sub_cat')"
                      >
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Category Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="subCategoryModal">
    <template v-slot:header>
      <h5 v-if="subform.key" class="modal-title">Update Sub-category</h5>
      <h5 v-else class="modal-title">Add Sub-Category</h5>
    </template>
    <form :method="formAction" @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input v-model="subform.name" class="form-control" name="name" placeholder="Name of the Sub-Category" required
                   type="text"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="subform.parent"
                :options="subCategoryAdd.parent"
                :required=true
                :searchable=true
                label="name"
                placeholder="Categories"
                track-by="name"
                open-direction="bottom"
                @search-change="asyncSubCategories"
            />
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-block" type="submit">{{ subform.key ? 'Update' : 'Add' }}</button>
    </form>
  </Modal>
  <!-- Category Modal -->

  <!-- SubCategory Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="categoryModal">
    <template v-slot:header>
      <h5 v-if="form.key" class="modal-title">Update Category</h5>
      <h5 v-else class="modal-title">Add Category</h5>
    </template>
    <form
        :method="formAction"
        @submit.prevent="submitHandler"
    >
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
                v-model="form.name"
                class="form-control"
                placeholder="Name of the Category"
                required
                type="text"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <VueMultiselect
                v-model="form.parent"
                :options="categoryAdd.parent"
                :required=true
                :searchable="false"
                label="name"
                placeholder="Select Section"
                track-by="name"
                open-direction="bottom"
            />
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-block" type="submit">{{ form.key ? 'Update' : 'Add' }}</button>
    </form>
  </Modal>
  <!-- SubCategory Modal -->

  <!-- Delete Modal -->
  <Modal :visible="modal" modalClasses="modal fade" modalId="deleteModal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button class="btn btn-primary mr-1 py-10" type="submit">Yes</button>
        <button class="btn btn-danger" data-dismiss="modal" type="button">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import VueMultiselect from 'vue-multiselect';

const categoryTableColumns = ["Name", "Section", "Sub Category", "Videos", "Action"];
const endpoint = '/category';

const subCategoryTableColumns = ["Name", "Section", "Category", "Videos", "Action"];
const sub_cat = 'sub_cat';

export default {
  name: "Categories",
  components: {
    PageBreadcrum,
    Table,
    Modal,
    VueMultiselect,
  },
  data() {
    return {
      tableColumns: [...categoryTableColumns],
      categoryAdd: {
        parent: [],
      },
      subCategoryAdd: {
        parent: [],
      },
      subCategoryTable: [...subCategoryTableColumns],
      //selected user for modal
      formUrl: endpoint,
      formAction: 'post',
      requestType: '',
      form: {
        key: "",
        name: "",
        children: [],
        parent: null,
        super_parent: "",
        total_video: 0,
      },
      subform: {
        key: "",
        name: "",
        children: [],
        parent: null,
        super_parent: "",
        total_video: 0,
      },
      modal: true,
    };
  },
  methods: {
    ...mapActions("Category", [
      "getCategories",
      "addCategories",
      "updateCategories",
      "deleteCategories",
      "getSubCategories",
      "addSubCategories",
      "updateSubCategories",
      "deleteSubCategories",
    ]),
    createOrUpdate: async function () {
      const Form = new FormData();
      const type = this.requestType == sub_cat ? 'subform' : 'form';

      Form.append("name", this[type].name);
      Form.append("parent", this[type].parent.key);
      Form.append('total_video', true);
      Form.append('sup_parent', true);
      Form.append('total_sub_cat', true);

      const parent = this.requestType == sub_cat ? this.subCategoryAdd.parent : this.categoryAdd.parent;


      if (this.formAction == "post") {
        if (this.requestType == sub_cat) {
          let res = await this.addSubCategories({Form, parent});
          return res;
        } else {
          let res = await this.addCategories({Form, parent});
          return res;
        }
      } else if (this.formAction == "put") {
        Form.append("key", this[type].key);
        Form.append("_method", "PATCH");
        if (this.requestType == sub_cat) {
          let res = await this.updateSubCategories({Form, parent});
          return res;
        } else {
          let res = await this.updateCategories({Form, parent});
          return res;
        }
      }
    },
    submitHandler(e) {
      if (this.formAction == "delete") {
        if (this.requestType == sub_cat) {
          this.deleteSubCategories(this.subform.key).then((res) => {
            this.modal = false;
            if (typeof res.data != 'undefined') {
              Swal.fire({
                title: "Success!",
                text: `Operation Successful!`,
                icon: "success",
                confirmButtonText: "Cool",
              });
            }
          })
              .catch((e) => {
                Swal.fire({
                  title: "Error!",
                  text: "Error occurred",
                  icon: "error",
                  confirmButtonText: "Cool",
                });
              });
        } else {
          this.deleteCategories(this.form.key).then((res) => {
            this.modal = false;
            if (typeof res.data != 'undefined') {
              Swal.fire({
                title: "Success!",
                text: `Operation Successful!`,
                icon: "success",
                confirmButtonText: "Cool",
              });
            }
          })
              .catch((e) => {
                Swal.fire({
                  title: "Error!",
                  text: "Error occurred",
                  icon: "error",
                  confirmButtonText: "Cool",
                });
              });
        }
      } else {
        this.createOrUpdate().then((res) => {
          this.modal = false;
          Swal.fire({
            title: "Success!",
            text: `Operation Successful!`,
            icon: "success",
            confirmButtonText: "Cool",
          });
        })
            .catch((e) => {
            });
      }
    },
    /*getEndpoint(type) {
      const form = new FormData();
      if (type == 'cat') {
        // "Name", "Section", "Sub Category", "Videos"
        form.append('c_type', 'cat');
        form.append('total_sub_cat', true);
      } else {
        form.append('c_type', 'sub_cat');
        form.append('sup_parent', true);
      }
      form.append('parent', true);
      form.append('total_video', true);
      let parameters = [...form.entries()]
          .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')
      axios.get(endpoint + '?' + parameters)
          .then((res) => {
            if (type == 'cat')
              this.tableColumns = res.data.data
            else
              this.subCategoryTable.data = res.data.data
          })
          .catch(err => console.log(err))
    },*/
//for sending information of selected user to modal
    sendInfo(item, formType, rqType) {
      if (rqType == 'sub_cat') {
        this.getParent('sub-cat')
      }
      this.formAction = formType;
      this.requestType = rqType;
      const type = this.requestType == sub_cat ? 'subform' : 'form';
      if (item) {
        this[type].key = item.key;
        this[type].name = item.name;
        this[type].children = item.children;
        this[type].parent = item.parent;
        this[type].super_parent = item.super_parent;
        this[type].total_video = item.total_video;
      } else {
        this[type].key = null;
        this[type].name = null;
        this[type].children = [];
        this[type].parent = null;
        this[type].super_parent = null;
        this[type].total_video = null;
      }
      this.modal = true;
    },
    getParent(type, qry = new FormData()) {
      let param = [...qry.entries()]
          .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1])).join('&')
      axios.get(endpoint + '/cat/' + type + '?' + param)
          .then((res) => {
            if (type == 'super-cat')
              this.categoryAdd.parent = res.data.data
            else
              this.subCategoryAdd.parent = res.data.data.filter((e) => {
                return e.key != this.subform.key
              })
          })
          .catch(err => console.log(err))
    },
    updateList(url) {
      this.getCategories(url)
    },
    updateSubList(url) {
      this.getSubCategories(url)
    },
    asyncSubCategories(query) {
      const form = new FormData();
      form.append('query', query);
      this.getParent('sub-cat', form);
    },
  },
  created() {
    // this.getEndpoint('cat');
    // this.getEndpoint('sub_cat');
    this.getParent('super-cat');
  },
  mounted() {
    this.getCategories();
    this.getSubCategories();
  },
  computed: {
    ...mapGetters("Category", ["categories", "sub_categories", "meta_categories", "meta_sub_categories"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>