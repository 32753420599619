<template>
  <div>
    <HrHeader pageHeading="HR Management" pageSubHeading="Settings" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">
            <div class="row">
              <div class="col-md-3 offset-md-9 text-right">
                <button
                  href="#hrsModal"
                  @click="sendInfo('', 'post')"
                  data-toggle="modal"
                  class="btn add text-right"
                >
                  <i class="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
            <Table
              tableClasses="card-table hr-table"
              :columns="table"
              v-if="hrs"
              :meta="meta"
              @getData="updateList"
            >
              <template v-slot:default>
                <tr v-for="(item, index) in hrs" :key="index">
                  <td>{{ item["key"] }}</td>
                  <td>{{ item["name"] }}</td>
                  <td>{{ item["email"] }}</td>
                  <td>{{ dateFormatter(item["created_at"], false) }}</td>
                  <td class="actions">
                    <a
                      class="btn btn-sm bg-success-light"
                      data-toggle="modal"
                      href="#hrsModal"
                      @click="sendInfo(item, 'put')"
                    >
                      <i class="fe fe-pencil"></i>
                    </a>
                    <a
                      data-toggle="modal"
                      href="#deleteModal"
                      class="btn btn-sm bg-danger-light"
                      @click="sendInfo(item, 'delete')"
                    >
                      <i class="fe fe-trash"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </Table>
          </div>

          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Hrs Modal -->
  <Modal modalClasses="modal fade" modalId="hrsModal" :visible="modal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.key">Update Hr</h5>
      <h5 class="modal-title" v-else>Add Hr</h5>
    </template>
    <form @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              required
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="!form.key">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              placeholder="Email"
              required
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="!form.key">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
              minlength="8"
              required
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="!form.key">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="password_confrm"
              type="password"
              class="form-control"
              placeholder="Password Confirmation"
              minlength="8"
              required
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">
        {{ form.key ? "Update" : "Add" }}
      </button>
    </form>
  </Modal>
  <!-- /Hrs Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button
          type="submit"
          class="btn btn-primary mr-1 py-10"
          ref="submitModal"
        >
          Yes
        </button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

const hrsTableColumns = ["Hr Id", "Name", "Email", "Date of Joining", "Action"];

export default {
  name: "HRManagement",
  components: {
    Card,
    Support,
    Table,
    Modal,
    HrHeader,
    HrSidebar,
  },
  data() {
    return {
      table: [...hrsTableColumns],
      //selected user for modal
      form: {},
      dob: null,
      password: null,
      password_confrm: null,
      formAction: "post",
      modal: false,
      modal: false
    };
  },
  methods: {
    ...mapActions("Hr", ["getHrs", "addHrs", "updateHrs", "deleteHrs"]),
    createOrUpdate: async function () {
      const User = new FormData();
      User.append("name", this.form.name);
      User.append("email", this.form.email);

      if (this.formAction == "post") {
        User.append("password", this.password);
        User.append("password_confirmation", this.password_confrm);
        let res = await this.addHrs(User);
        return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        let res = await this.updateHrs(User);
        return res;
      }
    },

    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";
      if (this.formAction == "delete") {
        this.deleteHrs(this.form.key).then((res) => {
          this.modal= false;
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
          .catch((e) => {});
      } else {
        this.createOrUpdate().then((res) => {
          this.modal= false;
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
          .catch((e) => {});
      }
    },
    sendInfo(item, formAction) {
      this.form = { ...item };
      this.formAction = formAction;
      this.dob = this.localDateFormat(item.dob);
      this.modal = true;
    },
    updateList(url) {
      this.getHrs(url);
    },
  },
  mounted() {
    this.getHrs();
  },
  computed: {
    ...mapGetters("Hr", ["hrs", "meta"]),
  },
};
</script>

<style>
</style>