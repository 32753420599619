import { mapActions, mapGetters } from "vuex";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import Cookies from "js-cookie";
import {TOKEN} from "@/store/config/constants";
export default {
    mounted(){

        // this.getuser(this.currentRouteName).then(data=>{

        // }).catch(e=>{
        //     console.log(e.response);

        //     Swal.fire({
        //         title: 'Error!',
        //         text: e.response.data.message,
        //         icon: 'error',
        //         confirmButtonText: 'Okay'
        //     });
        // });
    },
    methods: {
        ...mapActions('Auth', ['login','getuser','signup', 'clearToken', 'logout']),
        submit(type = 'login') {
            const User = new FormData();
            User.append("email", this.form.email);
            User.append("password", this.form.password);
            if (type == 'signup') {
                User.append("name", this.form.name);
                User.append("password_confirmation", this.form.pass_confrim);
                const token = Cookies.get(TOKEN);
                if (! token) {
                    this.signup(User, 123).then(res => {
                        if (typeof res.data != 'undefined') {
                            this.compReg();
                        }
                    }).catch(e => {
                        console.log(e);
                        Swal.fire({
                            title: 'Error!',
                            text: e.loginMessage,
                            icon: 'error',
                            confirmButtonText: 'Cool'
                        });
                    });
                } else {
                    this.compReg();
                }
            } else {
                var role = this.currentRouteName;
                if (!role) role = 'hr';
                User.append("role", role);

                this.login(User, 123).then(res => {
                    Swal.fire({
                        title: 'Login!',
                        text: `Welcome ${res.data.data.name}`,
                        icon: 'success',
                        confirmButtonText: 'Cool'
                    });
                    if (role == 'admin') {
                        this.$router.push({
                            name: 'UserManagement'
                        });
                    } else if (role == 'hr') {
                        this.$router.push({
                            name: 'HRDashboard'
                        });
                    }


                }).catch(e => {
                    console.log(e);
                });
            }
            
        },
    },
    computed: {
        currentRouteName() {
            if(this.$route.name.toLowerCase()=='home') {
                return '';
                
            }
            return this.$route.name.toLowerCase();
        },
        redirectTo() {
            return this.$route.name.toLowerCase();
        }
    }
};