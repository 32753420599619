import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Index/Home.vue'
import About from '../views/Index/About.vue'
import NotFound from '../views/Index/NotFound.vue'
import OurTrainer from '../views/Index/OurTrainers.vue'
import Contact from '../views/Index/Contact.vue'
import UserManagement from '../views/Admin/UserManagement.vue'
import PlanManagement from '../views/Admin/PlanManagement.vue'
import IndividualPlanManagement from '../views/Admin/IndividualPlanManagement.vue'
import SupportTicket from '../views/Admin/SupportTicket.vue'
import Trainers from '../views/Admin/Trainers.vue'
import Categories from '../views/Admin/Categories.vue'
import Videos from '../views/Admin/Videos.vue'
import Recipes from '../views/Admin/Recipes.vue'
import HRLogin from '../views/HR/Login.vue'
import AdminLogin from '../views/Admin/Login.vue';
import HRDashboard from '../views/HR/Dashboard.vue'
import HRSupport from '../views/HR/SupportDetails.vue'
import HREmployees from '../views/HR/Employees.vue'
import HRAppStatistics from '../views/HR/AppStatistics.vue'
import HRPhysicallyHappy from '../views/HR/PhysicallyHappy.vue'
import HRMentallyHappy from '../views/HR/MentallyHappy.vue'
import HRNutritionallyHappy from '../views/HR/NutritionallyHappy.vue'
import HRVideos from '../views/HR/Videos.vue'
import HRSubscription from '../views/HR/Subscription.vue'
import HRProfile from '../views/HR/Profile.vue'
import HRLoginActivity from '../views/HR/LoginActivity.vue'
import HRHelpSupport from '../views/HR/HelpSupport.vue'
import HRUserManagement from '../views/Admin/UserManagement.vue'
import Cookies from 'js-cookie'
import { TOKEN } from '@/store/config/constants'

import store from "@/store/index.js";
import Tags from '../views/Admin/Tags.vue'
import Ingredients from "@/views/Admin/Ingredients";
import Comments from "@/views/Admin/Comments";
import NewHR from "@/views/HR/HRManagement";
import HRManagement from "@/views/HR/HRManagement";
import AdminProfile from "@/views/Admin/AdminProfile";

import CorporateSubscriptions from "@/views/Admin/CorporateSubscriptions";

const routes = [
   {
      path: '/',
      name: 'Home',
      component: Home
   },
   {
      path: '/index',
      name: 'Index',
      component: Home
   },
   {
      path: '/about',
      name: 'About',
      component: About
   },
   {
      path: '/our-trainer',
      name: 'OurTrainer',
      component: OurTrainer
   }, {
      path: '/contact',
      name: 'Contact',
      component: Contact
   }, {
      path: '/admin',
      name: 'Admin',
      component: AdminLogin,
      meta: {
         hideNavbar: true,
         guest: true
      }
   }, {
      path: '/admin/user-management',
      name: 'UserManagement',
      component: UserManagement,
      meta: {
         requiresAuth: true,
         is_admin: true
      }
   }, {
      path: '/admin/plan-management',
      name: 'PlanManagement',
      component: PlanManagement
   }, {
      path: '/admin/support-ticket',
      name: 'SupportTicket',
      component: SupportTicket
   }, {
      path: '/admin/trainers',
      name: 'Trainers',
      component: Trainers
   }, {
      path: '/admin/categories',
      name: 'Categories',
      component: Categories
   }, {
      path: '/admin/videos',
      name: 'Videos',
      component: Videos
   }, {
      path: '/admin/recipes',
      name: 'Recipes',
      component: Recipes
   }, {
      path: '/admin/ingredients',
      name: 'Ingredients',
      component: Ingredients
   }, {
      path: '/admin/tags',
      name: 'Tags',
      component: Tags
   }, {
      path: '/admin/corporate-subscriptions',
      name: 'CorporateSubscriptions',
      component: CorporateSubscriptions
   },
   {
      path: '/admin/individual-plans',
      name: 'Individual Plans',
      component: IndividualPlanManagement
   },{
      path: '/HR',
      name: 'HR',
      component: HRLogin,
      meta: {
         hideNavbar: true,
         guest: true
      }
   }, {
      path: '/HR/user-management',
      name: 'HRUserManagement',
      component: HRUserManagement
   }, {
      path: '/HR/dashboard',
      name: 'HRDashboard',
      component: HRDashboard,
      meta: {
         requiresAuth: true,
         is_admin: true
      }
   }, {
      path: '/HR/employees',
      name: 'HREmployees',
      component: HREmployees
   }, {
      path: '/HR/app-statistics',
      name: 'HRAppStatistics',
      component: HRAppStatistics
   }, {
      path: '/HR/physically-happy',
      name: 'HRPhysicallyHappy',
      component: HRPhysicallyHappy
   }, {
      path: '/HR/mentally-happy',
      name: 'HRMentallyHappy',
      component: HRMentallyHappy
   }, {
      path: '/HR/nutritionally-happy',
      name: 'HRNutritionallyHappy',
      component: HRNutritionallyHappy
   }, {
      path: '/HR/subscription',
      name: 'HRSubscription',
      component: HRSubscription
   }, {
      path: '/HR/videos',
      name: 'HRVideos',
      component: HRVideos
   }, {
      path: '/HR/support',
      name: 'HRSupport',
      component: HRSupport
   }, {
      path: '/HR/profile',
      name: 'HRProfile',
      component: HRProfile
   }, {
      path: '/admin/profile',
      name: 'AdminProfile',
      component: AdminProfile
   }, {
      path: '/HR/new-hr',
      name: 'HRManagement',
      component: HRManagement
   }, {
      path: '/HR/login-activity',
      name: 'HRLoginActivity',
      component: HRLoginActivity
   }, {
      path: '/HR/help-support',
      name: 'HRHelpSupport',
      component: HRHelpSupport
   }, {
      path: '/admin/tags',
      name: 'Tags',
      component: Tags
   },
   {
      path: '/admin/support-ticket/comments',
      name: 'Comments',
      component: Comments,
      props(route) {
         return { ticketKey: route.query.ticketKey }
      }
   },
   {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound
   },
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
})

// router.beforeEach((to, from, next) => {
//    let user = Cookies.get(TOKEN);
//    let loggedIn = store.getters['Auth/isAuthenticated'];


//    if (to.matched.some(record => record.meta.requiresAuth)) {
//       if (user == null || user === undefined) {
//          next({
//             path: '/login',
//             params: { nextUrl: to.fullPath }
//          })
//       } else {
//          if (to.matched.some(record => record.meta.is_admin)) {
//             if (user.is_admin == 1) {
//                next()
//             }
//             else {
//                next({ name: 'UserManagement' })
//             }
//          } else {
//             next()
//          }
//       }
//    }
//    else if (to.matched.some(record => record.meta.guest)) {
//       if (user == null || user === undefined) {
//          next()
//       }
//       else {
//          next({ name: 'Home' })
//       }
//    } else {
//       next()
//    }
// })

export default router
