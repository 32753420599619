import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _url = "hr";

export default {
    namespaced: true,
    state: {
        hrs: null,
        meta :null
    },
    mutations: {
        loadHrs(state, hrs) {
            state.hrs = hrs.data;
            state.meta = hrs.meta;
        },
        addHrs(state, hr) {
            state.hrs.unshift(hr);
        },
        updateHrs(state, hr) {
            let index= state.hrs.findIndex(t => t.key === hr.key);
            state.hrs[index] = hr;
        },
        deleteHrs(state, hr) {
            let index= state.hrs.findIndex(t => t.key === hr);
            state.hrs.splice(index, 1);
        }
    },
    actions: {
        async getHrs({ commit }, url=null) {
            const res = await axios.get( url ?? _url);
            console.log(res.data);
            await commit('loadHrs', res.data);
            return res;
        },
        async addHrs({ commit }, Hr) {
            const res = await axios.post(_url, Hr);
            await commit('addHrs', res.data.data);
            return res;
        },
        async updateHrs({ commit }, Hr) {
            let url = `${_url}/${Hr.get('key')}`;
            const res = await axios.post(url, Hr);
            await commit('updateHrs', res.data.data);
            return res;
        },
        async deleteHrs({ commit }, key) {
            let url = `${_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteHrs', key);
            return res;
        },
        


    },
    modules: {
    },
    getters: {
        hrs: state => state.hrs,
        meta: state=> state.meta
    }
};