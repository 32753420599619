<template>
  <div>
    <HrHeader pageHeading="Home" pageSubHeading="Dashboard" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <div class="hr-header">
                  <p v-if="profile">
                    <img :src="profile.profile_pic" /> {{ welcomeMsg }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <!-- Sales Chart -->
                <Card cardClasses="card hr-card">
                  <template v-slot:default>
                    <div class="row">
                      <div class="col-sm-6">
                        <h3 class="chart-title">Overview</h3>
                        <div class="chart-details">
                          <p>Total watched videos (including multiple times) : {{ chart.total_watched }}</p>
                          <p>Total videos : {{ chart.total }}</p>
                          <p>Average watched videos : {{ chart.avg }}</p>
                        </div>
                      </div>
                      <div class="col-sm-3 offset-sm-3">
                        <select class="form-control" @change="updateChart">
                          <option value="M">Monthly</option>
                          <option value="W">Weekly</option>
                          <option value="D">Daily</option>
                        </select>
                      </div>
                    </div>
                    <vue3-chart-js
                      :id="chart.id"
                      :type="chart.type"
                      :data="chart.data"
                      ref="chartRef"
                    ></vue3-chart-js>
                  </template>
                  <!-- <LineChart :chartdata="overviewChartData.data" :options="overviewChartData.options"/> -->
                </Card>
                <!-- /Sales Chart -->
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-sm-6 col-12">
                <Card cardClasses="card">
                  <div class="dash-widget-header">
                    <!--<span class="dash-widget-icon text-primary border-primary">
                      <i class="fe fe-users"></i>
                    </span>-->
                    <div class="dash-count">
                      <h5>Physically Happy</h5>
                      <p>{{ stats.circular_stat.physically_happy.watched }}/{{ stats.circular_stat.physically_happy.total }}</p>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <div class="progress purple"  :class="'loading-'+setCirclePercent(setPercentage(stats.circular_stat.physically_happy.watched, stats.circular_stat.physically_happy.total))">
                      <span class="progress-left">
                        <span class="progress-bar"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar"></span>
                      </span>
                      <div class="progress-value">{{ setPercentage(stats.circular_stat.physically_happy.watched, stats.circular_stat.physically_happy.total) }}%</div>
                    </div>
                  </div>
                </Card>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <Card cardClasses="card">
                  <div class="dash-widget-header">
                    <!--<span class="dash-widget-icon text-success">
                      <i class="fe fe-credit-card"></i>
                    </span>-->
                    <div class="dash-count">
                      <h5>Mentally Happy</h5>
                      <p>{{ stats.circular_stat.mentally_happy.watched }}/{{ stats.circular_stat.mentally_happy.total }}</p>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <div class="progress orange" :class="'loading-'+setCirclePercent(setPercentage(stats.circular_stat.mentally_happy.watched, stats.circular_stat.mentally_happy.total))">
                      <span class="progress-left">
                        <span class="progress-bar"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar"></span>
                      </span>
                      <div class="progress-value">{{ setPercentage(stats.circular_stat.mentally_happy.watched, stats.circular_stat.mentally_happy.total) }}%</div>
                    </div>
                  </div>
                </Card>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <Card cardClasses="card">
                  <div class="dash-widget-header">
                    <!--<span class="dash-widget-icon text-danger border-danger">
                      <i class="fe fe-money"></i>
                    </span>-->
                    <div class="dash-count">
                      <h5>Nutritionally Happy</h5>
                      <p>{{ stats.circular_stat.nutritionally_happy.watched }}/{{ stats.circular_stat.nutritionally_happy.total }}</p>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <div class="progress green" :class="'loading-'+setCirclePercent(setPercentage(stats.circular_stat.nutritionally_happy.watched, stats.circular_stat.nutritionally_happy.total))">
                      <span class="progress-left">
                        <span class="progress-bar"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar"></span>
                      </span>
                      <div class="progress-value">{{ setPercentage(stats.circular_stat.nutritionally_happy.watched, stats.circular_stat.nutritionally_happy.total) }}%</div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {ref} from "vue";

const chart = {
  id: "overview",
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "Watched Videos",
        data: [],
        fill: true,
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        tension: 0.1,
      },
    ],
  },
  total_watched : 0,
  total : 0,
  avg : 0,
};
const url = 'hr/dashboard';

export default {
  name: "Dashboard",
  components: {
    Card,
    Support,
    HrHeader,
    HrSidebar,
    Vue3ChartJs,
  },
  data() {
    return {
      chart,
      stats: {
        circular_stat: {
          'physically_happy': {
            'watched': 0,
            'total': 0,
          },
          'mentally_happy': {
            'watched': 0,
            'total': 0,
          },
          'nutritionally_happy': {
            'watched': 0,
            'total': 0,
          },
        },
        graph : {}
      }
    };
  },
  methods: {
    ...mapActions("Auth", [
      "getProfile",
    ]),
    getDashboardStat(url, type) {
      axios.get(url)
      .then(res=> {
        this.stats[type] = {...res.data.data[type]};
        if (type == 'graph') {
          var avg = 0;
          let i = 0;
          this.chart.data.labels = [];
          this.chart.data.datasets[0].data = [];
          for(var key in this.stats.graph.data) {
            i++;
            this.chart.data.labels.push(key);
            const value = this.stats.graph.data[key];
            this.chart.data.datasets[0].data.push(value);
            avg+=value;
          }
          this.$refs.chartRef.update();
          this.chart.total_watched = res.data.data[type]['total_watched'];
          this.chart.total = res.data.data[type]['total'];
          if (i) this.chart.avg = (avg/i).toFixed(1);
          else this.chart.avg = 0;
        }
      })
      .catch(err=> console.log(err))
    },
    setPercentage(quantity, total) {
      if (! quantity) return quantity;
      return ((quantity/total)*100).toFixed(1);
    },
    setCirclePercent(val) {
      const per =  Math.round(val / 10) * 10;
      if(per > 100) return 100;
      return per;
    },
    updateChart(evnt) {
      this.getDashboardStat(`hr/dashboard-graph?graph_type=${evnt.target.value}&only_graph=1`, 'graph');
    }
  },
  mounted() {
    this.getProfile();
    this.getDashboardStat(url, 'circular_stat');
    this.getDashboardStat('hr/dashboard-graph?graph_type=M&only_graph=1', 'graph');
  },
  computed: {
    ...mapGetters("Auth", ["profile"]),
    welcomeMsg() {
      var welcome;
      var date = new Date();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      if (hour < 12) {
        welcome = "Good Morning,";
      } else if (hour < 17) {
        welcome = "Good Afternoon,";
      } else {
        welcome = "Good Evening,";
      }
      return `${welcome} ${this.profile.name}!`
    },
  },
};
</script>

<style>
</style>