<template>
  <form
    ref="forum"
    :action="fAction"
    :method="fMethod"
    :enctype="
      isMultiPart ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
    "
    @submit="submitHandler"
  >
    <slot></slot>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Form",
  props: {
    fMethod: String,
    fAction: String,
    isMultiPart: false,
    formData: {}
  },
  methods: {
    syncerMethod() {
      this.$emit("syncerMethod");
    },
    error(e) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        confirmButtonText: "Cool",
      });
    },
    success() {
      Swal.fire({
        title: "Done!",
        icon: "success",
        confirmButtonText: "Cool",
      });
    },
    submitHandler(event) {
      event.preventDefault();
      const data = new FormData(event.target);
      const furl = this.fAction;


      if (this.fMethod == 'patch' || this.fMethod == 'put') {
        data.append('_method', this.fMethod);
      }
      if (this.fMethod == 'delete') {
        axios.delete(furl)
            .then(res => {
              this.success();
              this.syncerMethod();
            })
            .catch(err => {
              this.error();
              console.log(err)
            });
      } else {
        this.callPost(furl, data).then(res => {
          if (res.status >= 200 && res.status < 300) {
            this.success();
            this.syncerMethod();
            this.$refs.forum.reset();
          }
        })
            // .catch(err => {
            //   this.error();
            //   console.log(err)
            // });
      }
    },
    callPost: async function(furl, data){
      let _res = await axios.post(furl, data)
      return _res;
    }
  },
};
</script>

<style scoped>
</style>