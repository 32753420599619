<template>
  <div>
    <HrHeader pageHeading="Home" pageSubHeading="App Statistics" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <!-- Sales Chart -->
                <Card cardClasses="card hr-card">
                  <template v-slot:default>
                    <div class="row">
                      <div class="col-sm-6">
                        <h3 class="chart-title">Physically Happy Overview</h3>
                      </div>
                      <div class="col-sm-5 offset-sm-1">
                        <select class="form-control" v-model="selected" @change="updateChart">
                         <option value="M">Monthly</option>
                          <option value="W">Weekly</option>
                          <option value="D">Daily</option>
                        </select>
                      </div>
                    </div>
                    <Vue3ChartJs
                      :id="physicallyChart.id"
                      :type="physicallyChart.type"
                      :data="physicallyChart.data"
                      ref="pChart"
                    ></Vue3ChartJs>
                    <p class="chart-link text-right">
                       <router-link :to="{ name: 'HRPhysicallyHappy' }">
                          View More
                       </router-link>
                    </p>
                  </template>
                </Card>
                <!-- /Sales Chart -->
              </div>

              <div class="col-md-6 col-sm-12">
                <!-- Sales Chart -->
                <Card cardClasses="card hr-card">
                  <template v-slot:default>
                    <div class="row">
                      <div class="col-sm-6">
                        <h3 class="chart-title">Mentally Happy Overview</h3>
                      </div>
                      <div class="col-sm-5 offset-sm-1">
                        <select class="form-control" v-model="selected" @change="updateChart">
                         <option value="M">Monthly</option>
                          <option value="W">Weekly</option>
                          <option value="D">Daily</option>
                        </select>
                      </div>
                    </div>
                    <Vue3ChartJs
                      :id="mentallyChart.id"
                      :type="mentallyChart.type"
                      :data="mentallyChart.data"
                      ref="mChart"
                    ></Vue3ChartJs>
                    <p class="chart-link text-right">
                       <router-link :to="{ name: 'HRMentallyHappy' }">
                          View More
                       </router-link>
                    </p>
                  </template>
                </Card>
                <!-- /Sales Chart -->
              </div>

              <div class="col-md-6 col-sm-12">
                <!-- Sales Chart -->
                <Card cardClasses="card hr-card">
                  <template v-slot:default>
                    <div class="row">
                      <div class="col-sm-6">
                        <h3 class="chart-title">Nutritionally Happy Overview</h3>
                      </div>
                      <div class="col-sm-5 offset-sm-1">
                        <select class="form-control" v-model="selected" @change="updateChart">
                         <option value="M">Monthly</option>
                          <option value="W">Weekly</option>
                          <option value="D">Daily</option>
                        </select>
                      </div>
                    </div>
                    <Vue3ChartJs
                      :id="nutritionallyChart.id"
                      :type="nutritionallyChart.type"
                      :data="nutritionallyChart.data"
                      ref="nChart"
                    ></Vue3ChartJs>
                    <p class="chart-link text-right">
                       <router-link :to="{ name: 'HRNutritionallyHappy' }">
                          View More
                       </router-link>
                    </p>
                  </template>
                </Card>
                <!-- /Sales Chart -->
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import axios from "axios";

const physicallyChart = {
  id: "physically",
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "Watched",
        data: [],
        fill: true,
        backgroundColor: "#b65da07c",
        tension: 0.1,
      },
    ],
  },
};

const mentallyChart = {
  id: "mentally",
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "Watched",
        data: [],
        fill: true,
        backgroundColor: "#d77c2288",
        tension: 0.1,
      },
    ],
  },
};

const nutritionallyChart = {
  id: "nutritionally",
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "Watched",
        data: [],
        fill: true,
        backgroundColor: "#17a3ad86",
        tension: 0.1,
      },
    ],
  },
};
const url = 'hr/app-statistics';

export default {
  name: "AppStatistics",
  components: {
    Card,
    Support,
    HrHeader,
    HrSidebar,
    Vue3ChartJs,
  },
  data() {
    return {
      selected : "M",
       physicallyChart,
       mentallyChart,
       nutritionallyChart,
    };
  },
  methods : {
    getDashboardStat(url) {
      axios.get(url)
          .then(res=> {
              this.physicallyChart.data.labels = [];
              this.physicallyChart.data.datasets[0].data = [];
              this.mentallyChart.data.labels = [];
              this.mentallyChart.data.datasets[0].data = [];
              this.nutritionallyChart.data.labels = [];
              this.nutritionallyChart.data.datasets[0].data = [];
              for(var key in res.data.data.graph_type.data) {

                this.physicallyChart.data.labels.push(key);
                this.mentallyChart.data.labels.push(key);
                this.nutritionallyChart.data.labels.push(key);

                const P = res.data.data.graph_type.data[key]['P'];
                const M = res.data.data.graph_type.data[key]['M'];
                const N = res.data.data.graph_type.data[key]['N'];

                this.physicallyChart.data.datasets[0].data.push(P);
                this.mentallyChart.data.datasets[0].data.push(M);
                this.nutritionallyChart.data.datasets[0].data.push(N);
            }
            console.log(this.physicallyChart);
            this.$refs.pChart.update();
            this.$refs.mChart.update();
            this.$refs.nChart.update();
          })
          .catch(err=> console.log(err))
    },
    updateChart(evt) {
      this.getDashboardStat(url+`?graph_type=${evt.target.value}&only_graph=1`);
    }
  },
  mounted() {
    this.getDashboardStat(url+`?graph_type=M&only_graph=1`);
  }
};
</script>

<style>
</style>