<template>
  <input ref="input" class="form-control-file" :id="id" :name="_name" type="file" accept="image/*" @change="loadFile">
  <img :class="imgClass" id="output" :src="img" alt="" style="display: none"/>
</template>

<script>
export default {
  name: "ImageUpload",
  props: {
    id : String,
    _name: String,
    img: null,
    img_class:null
  },
  methods: {
    loadFile(event) {
      var reader = new FileReader();
      reader.onload = function(){
        var output = document.getElementById('output');
        output.src = reader.result;
        output.style.display = "block";
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    getFile(){
      return this.$refs.input.files[0];
    },
    resetFile() {
      var output = document.getElementById('output');
      output.src = "";
      output.style.display = "none";
      this.$refs.input.value = '';
    }
  },
  computed: {
    imgClass() {
      return (this.img_class) ? this.img_class : '' +" mw-300";
    }
  }
}

</script>

<style scoped>
.mw-300 {
  max-width: 150px;
}
</style>