<template>
  <div>
    <!-- Availabe Features -->
    <section class="section section-features">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 features-img">
            <img
              :src="setImg('assets/img/feature.png')"
              class="img-fluid"
              alt="Feature"
            />
          </div>
          <div class="col-md-6">
            <div class="section-header">
              <h2 class="mt-2">Satisfying User Experience</h2>
              <p>
                Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit emit.Loreum
                Ipsum dollar sit emit.Loreum Ipsum dollar sit emit.Loreum Ipsum
                dollar sit emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar
                sit emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Availabe Features -->
    <!-- Availabe Features -->
    <section class="section section-features">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="section-header">
              <h2 class="mt-2">Satisfying User Experience</h2>
              <p>
                Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit emit.Loreum
                Ipsum dollar sit emit.Loreum Ipsum dollar sit emit.Loreum Ipsum
                dollar sit emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar
                sit emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.Loreum Ipsum dollar sit
                emit.Loreum Ipsum dollar sit emit.
              </p>
            </div>
          </div>
          <div class="col-md-6 features-img">
            <img
              :src="setImg('assets/img/banner-1.png')"
              class="img-fluid"
              alt="Feature"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- /Availabe Features -->
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
// @ is an alias to /src

import Export from "../../../vue.config";

export default {
  name: "Home",
  data() {
    return {
      app_url: Export.publicPath,
    };
  },
  mounted(){
  },
  components: {},
  methods: {
  },
};
</script>

<style>
</style>