<template>
  <div>
    <!-- Header -->
    <div class="header">
      <!-- Mobile-view Logo -->
      <div class="header-left">
        <!--<router-link :to="{ name: 'HRDashboard' }" class="logo logo-small">
          <img
            :src="setImg('assets/images/logo-small.png')"
            alt="Logo"
            width="30"
            height="30"
          />
        </router-link>-->
        <div class="hr-page-header">
           <p v-if="pageHeading" v-html="pageHeading"></p>
           <h4 v-if="pageSubHeading" v-html="pageSubHeading"></h4>
        </div>
      </div>
      <!-- /Mobile-view Logo -->

      <!-- sidebar toggler -->
      <!-- <a href="javascript:void(0);" id="toggle_btn">
        <i class="fe fe-text-align-left"></i>
      </a> -->
      <!-- /sidebar toggler -->

      <!-- Header Right Menu -->
      <ul class="nav user-menu">
        <li class="nav-item">
          <!-- search form -->
<!--   <div class="top-nav-search">
            <form>
              <input
                type="text"
                class="form-control"
                placeholder="Search here"
              />
              <button class="btn" type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>-->
          <!-- /search form -->
        </li>
        <li class="nav-item">
          <!-- Logo -->
          <router-link :to="{ name: 'HRDashboard' }" class="logo">
            <img
              :src="setImg('assets/images/logo-small.png')"
              alt="Logo"
              width="70"
            />
          </router-link>
          <!-- /Logo -->
        </li>
      </ul>
      <!-- /Header Right Menu -->

      <!-- Mobile Menu Toggle -->
      <a class="mobile_btn" id="mobile_btn">
        <i class="fa fa-bars"></i>
      </a>
      <!-- /Mobile Menu Toggle -->
    </div>
    <!-- /Header -->
  </div>
</template>

<script>
import Export from "../../vue.config";

export default {
  name: "AdminHeader",
  props: {
    pageHeading: String,
    pageSubHeading: String
  },
  components: {},

  data() {
    return {
      app_url: Export.publicPath,
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_BASE_URL);
  },
  methods: {

  },
};
</script>

<style>
</style>
