import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";
export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        setUser(state, email) {
            state.user = email
        },
        LogOut(state) {
            state.user = null
            state.posts = null
        },
    },
    actions: {
        async login({ commit }, User, role = '') {
            let url= '/login';
            if(role!=''){
                url= `${role}/login`;
            }
                
            const res = await axios.post(url, User);

            if (typeof res.data === 'object' && res !== null) {
                Cookies.set(TOKEN, res.data.token.access_token);
            }
            else {
                let err = { loginMessage: res.data };
                throw err;
            }
            await commit('setUser', User.get('email'))

            if (role == "hr") {

            }
            return res;
        },
        async logout({ commit }) {
            let user = null
            commit('logout', user)
        }

    },
    modules: {
    },
    getters: {
        // isAuthenticated: (state) => !!state.user,
    }
};