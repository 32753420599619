<template>
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum
          title="Administrator Dashboard | Individual Plan Management"
        />

        <div class="row">
          <div
            class="col-sm-12 col"
            style="text-align: right; margin-bottom: 5px"
          >
            <button
              href="#planModal"
              data-toggle="modal"
              class="btn add"
              @click="sendInfo(false, 'post')"
            >
              <i class="fa fa-plus"></i>
              Add
            </button>
            <span></span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Recent Orders -->

            <Table tableClasses="card-table" :columns="table.columns" >
              <template v-slot:default>
                <!-- making td accoding to number of columns -->
                <tr v-for="(item, index) in plans" :key="index">
                  <td v-for="column in table.columns" :key="column">
                    <span> {{ mutateColumnName(item, column) }}</span>
                    <div v-if="column == 'Action'" class="actions">
                      <a
                        class="btn btn-sm bg-success-light"
                        data-toggle="modal"
                        href="#planModal"
                        @click="sendInfo(item, 'put')"
                      >
                        <i class="fe fe-pencil"></i>
                      </a>
                      <!-- <a
                        data-toggle="modal"
                        href="#deleteModal"
                        class="btn btn-sm bg-danger-light"
                        @click="sendInfo(item, 'delete')"
                      >
                        <i class="fe fe-trash"></i>
                      </a> -->
                    </div>
                  </td>
                </tr>
              </template>
            </Table>

            <!-- /Recent Orders -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Plan Modal -->
  <Modal modalClasses="modal fade" modalId="planModal" :visible="modal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.plan_id">Update Plan</h5>
      <h5 class="modal-title" v-else>Add Plan</h5>
    </template>
    <form :method="formAction" @submit.prevent="submitHandler">
      <div class="row form-row" v-if="form.plan_id==''">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <label for="amount">Amount</label>
            <input
              v-model="form.amount"
              id="amount"
              type="number"
              step="any"
              class="form-control"
              placeholder="Amount(GBP)"
              required
            />
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <label for="nickname">Name(Stripe)</label>
            <input
              v-model="form.nickname"
              type="text"
              class="form-control"
              placeholder="Name"
              required
            />
          </div>
        </div>
      </div>
       <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="form.status" required id="status">
              <option value="" selected="selected">Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-12 col-sm-12" v-if="!form.plan_id">
          <div class="form-group">
             <label for="interval">Interval</label>
            <select class="form-control" v-model="form.interval" required id="interval">
              <option value="" selected="selected">Interval</option>
              <option value="month">Month</option>
              <option value="quarter">Quarter</option>
              <option value="year">Year</option>
              <option value="week">Week</option>
              <option value="day">Day</option>
            </select>
          </div>
        </div>
         <div class="col-12 col-sm-12">
          <div class="form-group">
             <label for="trial">Trial</label>
            <select class="form-control" v-model="form.trial" required id="trial">
              <option value="" selected="selected">Trial</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">{{form.plan_id ? 'Update' : 'Add'}}</button>
    </form>
  </Modal>
  <!-- Plan Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button type="submit" class="btn btn-primary mr-1 py-10">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import axios from 'axios';

const tableColumns = [
  "Name",
  "Amount",
  "Status",
  "Trial",
  "Recurring",
  "Action"
];
const tableData = [
  {
    plan: "2Hr",
    type: "Corporate",
    cost: "$500",
    validity: "30 Days",
    status: "Active",
  },
  {
    plan: "2Hr",
    type: "Corporate",
    cost: "$500",
    validity: "30 Days",
    status: "Active",
  },
  {
    plan: "2Hr",
    type: "Corporate",
    cost: "$500",
    validity: "30 Days",
    status: "Active",
  },
];

export default {
  name: "PlanManagement",
  components: {
    PageBreadcrum,
    Table,
    Modal,
  },
  data() {
    return {
      table: {
        columns: [...tableColumns],
        data: [...tableData],
      },
      form: {
        amount: "",
        nickname: "",
        interval: "",
        trial: "",
        plan_id: "",
        status: ""
      },
      //selected user for modal
      selectedUser: "",
      formAction:"post",
      modal: false,
      plans: []
    };
  },
  methods: {
    // ...mapActions("CorporatePlan", [
    //   "getPlans",
    //   "addPlans",
    //   "updatePlans",
    //   "deletePlans",
    // ]),
    getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach((key) => formData.append(key, object[key]));
      return formData;
    },

    //for sending information of selected user to modal
    sendInfo(item, formType) {
      this.modal= true;
      this.formAction = formType;
      if (!item) {
        for (let [key, value] of Object.entries(this.form)) {
          this.form[key] = "";
          // console.log(`${key}: ${value}`);
        }
      } else {
        this.form.nickname= item.nickname;
        this.form.amount= Number(item.unit_amount)*100;
        this.form.plan_id= item.id;
        this.form.status= item.active;
        this.form.trial= item.metadata.trial;
        this.form.interval= item.recurring.interval;

        // this.form.name = item.name;
        // this.form.key = item.key;
        // this.form.max_users = item.max_users;
        // this.form.status = item.status;
        // this.form.description = item.meta.description;
        // this.form.price = item.meta.price;
        // this.form.validity = item.validity;
      }
    },
    createOrUpdate: async function () {
      let formData= this.form;
      let url= formData.plan_id==''?'/admin/price':'/admin/price/'+formData.plan_id;

      const User = this.getFormData(this.form);
      return await axios.post(url, User);
      
    },
    submitHandler(e) {
        this.createOrUpdate()
          .then((res) => {
            this.modal= false;
           
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
            this.$router.go();
          })
            .catch((e) => {
              console.log(e);
            });
    },

    /**
     * Use it only for the meta values
     */
    mutateColumnName(item, column) {
      let meta = ["Description", "Price"];
      if(column==='Name')
       return item.nickname;
      if(column==='Amount')
       return Number(item.unit_amount)/100;
      if(column==='Status')
       return Boolean(item.active)==true?"Active":"Inactive"
      if(column==='Trial')
       return Boolean(item.metadata.trial)==true?"Yes":"No";
      if(column==='Recurring')
       return item.recurring.interval;
     

      return item[column.replace(/\s/g, "").toLowerCase()];
    },
    planValidity() {
      return {
        30 : 30,
        90 : 90,
        180 : 180,
        270 : 270,
        365 : 365,
      };
    },
    countHandler() {
      return {
        30 : 30,
        75 : 75,
        100 : 100,
        250 : 250,
        500 : 500,
        1000 : 1000,
      };
    },
   
    getStripePlans(){
      let res= axios.get('/admin/plans');
      res.then(res=>{
          this.plans= res.data.data;
          // console.log(this.plans);
      }).catch(e=>{

      });
    },
    
  },
  mounted() {
    // this.getPlans();
    this.getStripePlans();
  },
};
</script>

<style>
</style>