<template>
  <div>
    <HrHeader pageHeading="Home" pageSubHeading="Support Ticket" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12">

            <Card cardClasses="card">
              <template v-slot:default v-if="comments">
                <div class="support-header">
                  <div class="support-header-data d-flex">
                    <img
                      class="rounded-circle"
                      alt=""
                      :src="profile.profile_pic"
                      v-if="profile.profile_pic"
                    />
                    <div class="pt-1">
                      <div class="support-name">{{comments.submitted_by}}</div>
                      <div class="support-time">{{ dateFormatter(comments.created_at) }}</div>
                    </div>
                  </div>
                  <div class="support-header-icons">
                    <i class="fa fa-reply mr-4" @click="$router.go(-1)" style="cursor: pointer"></i>
                    <i class="fa fa-ellipsis-v"></i>
                  </div>
                </div>

                <div class="support-data mt-2 mb-4">
                  <div class="support-data-top mb-2">
                    <p>Subject : {{ comments.subject }}</p>
                    <p>Department : {{ comments.department }}</p>
                    <p>Priority : {{ comments.priority ? comments.priority : '-' }}</p>
                  </div>
                  <div class="support-data-bottom">
                    <img
                        class="max-150"
                        alt=""
                        :src="comments.attachment"
                        v-if="comments.attachment"
                    />
                  </div>
                  <div class="support-data-bottom">
                    {{comments.description}}
                  </div>
                  <div class="row" style="background:#f8f8f8">
                    <div class="col-md-6">
                  <div class="search-container bg-white p-2"  v-for="comment in comments.replies" :key="comment.key">
                    <div class="st-body">
                      <div class="avatar">
                      <img class="rounded-circle" :src="comment['profile_pic']" ></div>
                      <div class="ticket-content">
                      <p class="ticket-description">{{comment['reply']}}</p>
                      <template v-if="comment['attachment']">
                        <img :src="comment['attachment']"
                             width="100" />
                      </template>
                    </div>
                    
                      
                    
                
                    </div>
                    
                <div class="st-foot">
                  <span class="label" style="font-weight: bold">{{
                    comment["name"]
                  }}</span
                  ><span class="label">Shared:</span
                  ><span class="value">{{
                    dateFormatter(comment["created_at"])
                  }}</span>
                </div>
                </div>

                  
                  </div>
  <div class="col-md-6">
                <div class="support-reply d-flex mt-4">
                  <img
                    class="rounded-circle"
                    alt="User Image"
                    :src="profile.profile_pic"
                    v-if="profile"
                  />
                  <div class="w-100">
                    <form @submit.prevent="submitHandler">
                      <ImageUpload
                          name="attachment"
                          id="attachment"
                          ref="img"
                          :img="form.attachment"
                      />
                      <div class="form-group position-relative">
                        <textarea class="form-control" v-model="form.reply" required></textarea>
                        <button type="submit" class="btn btn-primary">
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div></div>
</div>
                  
                </div>
              </template>
            </Card>
            
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import {mapActions, mapGetters} from "vuex";
import ImageUpload from "@/components/Form/ImageUpload";
import Swal from "sweetalert2";

export default {
  name: "Support",
  components: {
    Card,
    HrHeader,
    HrSidebar,
    ImageUpload,
  },
  data() {
    return {
      form: {
        reply : "",
        attachment: "",
      },
    };
  },
  methods: {
    ...mapActions("Comment", [
      "getComments",
      "addComments"
    ]),
    ...mapActions("Auth", [
      "getProfile",
    ]),
    submitHandler() {
      let Form = new FormData();
      let img = this.$refs.img.getFile();

      Form.append('reply', this.form.reply);
      if(img) Form.append('attachment', img);

      this.addComments(Form).then((res) => {
        Swal.fire({
          title: "Success!",
          text: `Operation Successful!`,
          icon: "success",
          confirmButtonText: "Cool",
        });
        this.form = {
          reply : "",
          attachment: "",
        };
        this.$refs.img.resetFile()
      })
          .catch((e) => {});
      ;
    }
  },
  mounted() {
    this.getComments();
    this.getProfile()
  },
  computed: {
    ...mapGetters("Comment", ["comments"]),
    ...mapGetters("Auth", ["profile"]),
  },
};
</script>

<style>
.max-150 {
  max-width: 150px;
}
</style>