import { createStore } from "vuex";
import axios from 'axios';
import { TOKEN } from "../config/constants";
import Cookies from "js-cookie";

const _user_url = "users";

export default {
    namespaced: true,
    state: {
        users: null,
        meta: null,
    },
    mutations: {
        loadUsers(state, users) {
            state.users = users.data;
            state.meta = users.meta;
        },
        /*addUsers(state, user) {
            state.users.unshift(user);
        },*/
        updateUsers(state, user) {
            let index= state.users.findIndex(t => t.key === user.key);
            state.users[index] = user;
        },
        /*deleteUsers(state, user) {
            let index= state.users.findIndex(t => t.key === user);
            state.users.splice(index, 1);
        }*/
    },
    actions: {
        async getUsers({ commit }, url = `${_user_url}?user_type=individual`) {
            const res = await axios.get(url);
            await commit('loadUsers', res.data);
            return res;
        },
        /*async addUsers({ commit }, User) {
            const res = await axios.post(_user_url, User);
            await commit('addUsers', res.data.data);
            return res;
        },*/
        async updateUsers({ commit }, User) {
            let url = `${_user_url}/${User.get('key')}`;
            const res = await axios.post(url, User);
            await commit('updateUsers', res.data.data);
            return res;
        },
        /*async deleteUsers({ commit }, key) {
            let url = `${_user_url}/${key}`;
            const res = await axios.delete(url);
            await commit('deleteUsers', key);
            return res;
        },*/
    },
    modules: {
    },
    getters: {
        users: state => state.users,
        meta: state => state.meta,
    }
};