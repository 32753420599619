<template>
  <div>
    <HrHeader pageHeading="Employees" pageSubHeading="Users" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">
            <div class="row">
              <div class="col-md-5 offset-md-7 text-right form-inline">
                <div class="form-group mr-2">
                  <input class="form-control" placeholder="Search by Name /E-Mail" type="text"  v-model="filterQuery"/>
                </div>
                <button
                    class="btn add text-right mr-2"
                    @click="filterTable"
                >
                  <i class="fa fa-filter"></i> Filter
                </button>

                <button
                  href="#employeesModal"
                  @click="sendInfo('', 'post')"
                  data-toggle="modal"
                  class="btn add text-right"
                >
                  <i class="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
            <Table
              tableClasses="card-table hr-table"
              :columns="table"
              v-if="employees"
              :meta="meta"
              @getData="updateList"
            >
              <template v-slot:default>
                <tr v-for="(item, index) in employees" :key="index">
                  <td>{{ item["key"] }}</td>
                  <td>{{ item["name"] }}</td>
                  <td>{{ item["email"] }}</td>
                  <td>{{ dateFormatter(item["created_at"], false) }}</td>
                  <td>{{ dateFormatter(item["dob"], false) }}</td>
                  <td>
                    <span v-if="item['active'] == 'active'" class="green">
                      Active
                    </span>
                    <span v-else class=""> Not Logged In </span>
                  </td>
                  <td class="actions">
                    <a
                      class="btn btn-sm bg-success-light"
                      data-toggle="modal"
                      href="#employeesModal"
                      @click="sendInfo(item, 'put')"
                    >
                      <i class="fe fe-pencil"></i>
                    </a>
                    <a
                      data-toggle="modal"
                      href="#deleteModal"
                      class="btn btn-sm bg-danger-light"
                      @click="sendInfo(item, 'delete')"
                    >
                      <i class="fe fe-trash"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </Table>
          </div>

          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Dormant Users</h3>
          </div>
          <div class="col-md-12 col-lg-9">
            <Table
                tableClasses="card-table hr-table"
                :columns="table"
                v-if="employeesD"
                :meta="metaD"
                @getData="updateListD"
            >
              <template v-slot:default>
                <tr v-for="(item, index) in employeesD" :key="index">
                  <td>{{ item["key"] }}</td>
                  <td>{{ item["name"] }}</td>
                  <td>{{ item["email"] }}</td>
                  <td>{{ dateFormatter(item["created_at"], false) }}</td>
                  <td>{{ dateFormatter(item["dob"], false) }}</td>
                  <td>
                    <span class="red">
                      Inactive
                    </span>
                  </td>
                  <td class="actions">
                    <a
                        class="btn btn-sm bg-success-light"
                        data-toggle="modal"
                        href="#employeesModal"
                        @click="sendInfo(item, 'put', true)"
                    >
                      <i class="fe fe-pencil"></i>
                    </a>
                    <a
                        data-toggle="modal"
                        href="#deleteModal"
                        class="btn btn-sm bg-danger-light"
                        @click="sendInfo(item, 'delete', true)"
                    >
                      <i class="fe fe-trash"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Employees Modal -->
  <Modal modalClasses="modal fade" modalId="employeesModal" :visible="modal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.key">Update Employee</h5>
      <h5 class="modal-title" v-else>Add Employee</h5>
    </template>
    <form @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              placeholder="Email"
              required
              :disabled="form.key"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="!form.key">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
              v-model="dob"
              type="date"
              class="form-control"
              placeholder="DOB"
              title="DOB"
              required
              :disabled="form.key"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
              v-model="form.postcode"
              type="text"
              class="form-control"
              placeholder="Postalcode"
              required
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">
        {{ form.key ? "Update" : "Add" }}
      </button>
    </form>
  </Modal>
  <!-- /Employees Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal" :visible="modal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <form :method="formAction" @submit.prevent="submitHandler">
        <button
          type="submit"
          class="btn btn-primary mr-1 py-10"
          ref="submitModal"
        >
          Yes
        </button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          No
        </button>
      </form>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

const employeesTableColumns = [
  "Employee Id",
  "Name",
  "Email",
  "Date of Joining",
  "DOB",
  "Status",
  "Action",
];

export default {
  name: "Dashboard",
  components: {
    Card,
    Support,
    Table,
    Modal,
    HrHeader,
    HrSidebar,
  },
  data() {
    return {
      table: [...employeesTableColumns],
      //selected user for modal
      form: {},
      dob: null,
      password: null,
      formAction: "post",
      modal: false,
      isDor: false,
      filterQuery : "",
    };
  },
  methods: {
    ...mapActions("Employee", [
      "getEmployees",
      "addEmployees",
      "updateEmployees",
      "deleteEmployees",
      "getEmployeesD",
      "updateEmployeesD",
      "deleteEmployeesD",
    ]),
    createOrUpdate: async function () {
      const User = new FormData();
      User.append("name", this.form.name);
      User.append("email", this.form.email);
      User.append("postcode", this.form.postcode);
      User.append("dob", this.dob);

      if (this.formAction == "post") {
        User.append("password", this.password);
        let res = await this.addEmployees(User);
        return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        if (this.isDor) {
          var res = await this.updateEmployeesD(User);
          return res;
        }
        var res = await this.updateEmployees(User);
        return res;
      }
    },

    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";
      if (this.formAction == "delete") {
        if (this.isDor) {
          this.deleteEmployeesD(this.form.key)
              .then((res) => {
                this.modal=false;

                if (typeof res.data != 'undefined') {
                  Swal.fire({
                    title: "Success!",
                    text: `Operation Successful!`,
                    icon: "success",
                    confirmButtonText: "Cool",
                  });
                }
              })
              .catch((e) => {
              });
        }else {
          this.deleteEmployees(this.form.key)
              .then((res) => {
                if (typeof res.data != 'undefined') {
                  this.modal=false;

                  Swal.fire({
                    title: "Success!",
                    text: `Operation Successful!`,
                    icon: "success",
                    confirmButtonText: "Cool",
                  });
                }
              })
              .catch((e) => {
              });
        }
      } else {
        this.createOrUpdate()
          .then((res) => {
            this.modal=false;
            if (typeof res.data != 'undefined') {
              Swal.fire({
                title: "Success!",
                text: `Operation Successful!`,
                icon: "success",
                confirmButtonText: "Cool",
              });
            }
          })
          .catch((e) => {});
      }
    },
    sendInfo(item, formAction, dor = false) {
      this.form = { ...item };
      this.formAction = formAction;
      this.isDor = dor;
      this.dob = this.localDateFormat(item.dob);
      this.modal = true;
    },
    updateList(url) {
      this.getEmployees(url);
    },
    updateListD(url) {
      this.getEmployeesD(url);
    },
    filterTable() {
      this.getEmployees(`users?active=1&query=${this.filterQuery}`);
      this.getEmployeesD(`users?active=0&query=${this.filterQuery}`);
    },
  },
  mounted() {
    this.getEmployees();
    this.getEmployeesD();
  },
  computed: {
    ...mapGetters("Employee", ["employees", "meta", "employeesD", "metaD"]),
  },
};
</script>

<style>
</style>