<template>
  <div class="custom-element">
    <h1>This is about us page</h1>
  </div>
</template>

<script>

export default {
  name: "About",
  components: {
  },
};
</script>

<style>
   
.custom-element {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
</style>>
