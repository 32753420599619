<template>
  <Card cardClasses="card supportCard">
    <template v-slot:header>
      <h4 class="card-title text-center">
        <i class="fa fa-headphones float-left position-relative top-2"></i>
        Support Tickets
        <a data-toggle="collapse" href="#supportDetails">
          <i
              class="fa fa-sort-down float-right position-relative top-minus2"
          ></i>
        </a>
      </h4>
    </template>
    <template v-slot:default>
      <div id="supportDetails" v-if="tickets">
        <div class="card-row" v-for="ticket in tickets" :key="ticket">

          <router-link :to="{ name: 'HRSupport', query: {ticketKey: ticket['key']} }" class="d-flex">
            <div class="img-icn"><img :src="ticket.attachment" v-if="ticket.attachment"/></div>
            <div class="cont">
              {{ ticket.subject }} <br />
              <span> {{ dateFormatter(ticket.created_at) }}</span>
            </div>
            <div class="ic-cn"><i class="fa fa-envelope"></i></div>
          </router-link>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Support",
  components: {
    Card,
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions("Ticket", [
      "getTickets",
    ]),
  },
  mounted() {
    this.getTickets();
  },
  computed: {
    ...mapGetters("Ticket", ["tickets"]),
  },
};
</script>

<style>
</style>