<template>
  <div
    :class="modalClasses"
    :id="modalId"
    aria-hidden="true"
    role="dialog"
    :close="close"
  >
    <div :class="modalExtraClass" role="document">
      <div class="modal-content">
        <div class="modal-header" v-if="$slots.header">
          <slot name="header"></slot>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            ref="Close"
            @click="hideModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-header" style="display: none" v-else>
          <slot name="header"></slot>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            ref="Close"
            @click="hideModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="$slots.default">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="$slots.footer">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalId: [String, Object],
    modalClasses: [String, Object, Array],
    modalSize: String,
    visible: Boolean,
  },
  ref: "modalRef",
  computed: {
    modalExtraClass() {
      return "modal-dialog modal-dialog-centered " + this.$props.modalSize;
    },
  },
  methods: {
    doSomethingOnHidden() {},
    close() {
      this.$refs.Close.click();
    },
    hideModal() {
      this.$emit("hide");
    },
  },
  mounted() {
    $(this.$refs.customModal).on("hidden.bs.modal", this.doSomethingOnHidden);
  },
  watch: {
    visible: function (newVal, oldVal) {
      // watch it
     
      if (!newVal) {
        this.$refs.Close.click();
      }
    },
  },
};
</script>

<style>
</style>