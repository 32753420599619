<template>
  <div>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <PageBreadcrum title="Administrator Dashboard | User Management" />

        <!-- /Page Header -->
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col-md-4">
                  <ul class="nav nav-tabs nav-tabs-solid nav-justified">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#solid-justified-tab1"
                        data-toggle="tab"
                        @click="()=>{this.isPersonal= true}"
                        >Personal</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#solid-justified-tab2"
                        data-toggle="tab"
                        @click="()=>{this.isPersonal= false}"
                        >Corporate</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row pt-2">
                <div class="offset-md-4 col-md-8">
                  <div class="row d-flex justify-content-end">
                    <div class="col-sm-4" v-if="isPersonal">
                      <select v-model="searchByStatus" class="form-control">
                        <option value="">All Status</option>
                        <option value="1">Active</option>
                        <option value="2">Non-active</option>
                      </select>
                    </div>
                    <div class="col-sm-4">
                      <input class="form-control" placeholder="Search by Name /E-Mail" type="text"  v-model="filterQuery"/>
                    </div>
                    <div class="col-sm-2">
                      <button
                        class="btn add text-right mr-2"
                        data-toggle="modal"
                        @click="filterTable"
                    >
                      <i class="fa fa-filter"></i> Filter
                    </button></div>
                  </div>
                </div>
              </div>
              <div class="tab-content">
                <div class="tab-pane show active" id="solid-justified-tab1">
                  <!-- personal table -->
                  <Table
                    tableClasses="card-table"
                    :columns="personalTable"
                    v-if="users" :meta="meta" @getData="updateList"
                  >
                    <template v-slot:default>
                      <!-- making td accoding to number of columns -->
                      <tr v-for="(item, index) in users" :key="index">
                        <td>{{ item["name"] }}</td>
                        <td>
                          <span>{{ subHandler(item, "Plan") }}</span>
                        </td>
                        <td>
                          <span>{{subHandler(item, "Status")}}</span>
                        </td>
                        <td>
                          <span>{{ subHandler(item, "PlanStart") }}</span>
                        </td>
                        <td>
                          <span>{{ subHandler(item, "Ends At (Days)") }}</span>
                        </td>
                        <td>{{ item["email"] }}</td>
                        <td>{{ dateFormatter(item["dob"], false) }}</td>
                        <td>{{ item["postcode"] }}</td>
                        <td>
                        <span>{{ subHandler(item, "Device") }}</span>
                        </td>
                        <td class="actions">
                          <a
                            class="btn btn-sm bg-success-light"
                            data-toggle="modal"
                            href="#personalUserModal"
                            @click="sendInfo(item, 'put')"
                          >
                            <i class="fe fe-pencil"></i>
                          </a>
                          <!--                          <a
                              data-toggle="modal"
                              href="#deleteModal"
                              class="btn btn-sm bg-danger-light"
                          >
                            <i class="fe fe-trash"></i>
                          </a>-->
                        </td>
                      </tr>
                    </template>
                  </Table>
                  <!-- /personal table -->
                </div>
                <div class="tab-pane" id="solid-justified-tab2">
                  <!--                  <div class="col-sm-12 col" style="text-align: right">
                    <button
                      href="#corporateUserModal"
                      data-toggle="modal"
                      class="btn add"
                      @click="sendInfo('', '')"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                    <span>Add</span>
                  </div>-->

                  <!-- corporate table   -->
                  <Table
                    tableClasses="card-table"
                    :columns="corporateTable"
                    v-if="corpuser" :meta="corpmeta" @getData="updateCorpList"
                  >
                    <template v-for="(item, index) in corpuser" :key="index">
                      <!-- making td accoding to number of columns -->
                      <tr>
                        <td>
                          <span>
                            <button
                              type="button"
                              class="btn blue"
                              data-toggle="collapse"
                              :data-target="'#managementDetails_' + index"
                            >
                              <i class="fa fa-caret-right"></i>
                            </button>
                          </span>
                        </td>

                        <td>
                          <span>{{ item["name"] }}</span>
                        </td>
                        <td>
                          <span>{{
                            item["subscription"]["plan"]["name"]
                          }}</span>
                        </td>
                        <td>
                          <span>{{ item["hrs"][0]["email"] }}</span>
                        </td>
                        <td>
                          <span>{{ item["subscription"]["user_count"] }}</span>
                        </td>
                        <!--                        <td><span><a :href="item['document']">document</a></span></td>-->
                        <td>
                          <span>{{ item["code"] }}</span>
                        </td>
                        <td>
                          <span>{{
                            dateFormatter(
                              item["subscription"]["activated_at"],
                              false
                            )
                          }}</span>
                        </td>
<!--                        <td>
                          &lt;!&ndash;                          <a
                            class="btn btn-sm bg-success-light"
                            data-toggle="modal"
                            href="#corporateUserModal"
                            @click="sendInfo(item, '')"
                        >
                          <i class="fe fe-pencil"></i>
                        </a>
                          <a
                              data-toggle="modal"
                              href="#deleteModal"
                              class="btn btn-sm bg-danger-light"
                          >
                            <i class="fe fe-trash"></i>
                          </a>&ndash;&gt;
                        </td>-->
                      </tr>
                      <tr
                        :id="'managementDetails_' + index"
                        class="collapse out"
                      >
                        <td colspan="8" class="text-left colspan-td">
                          <div class="table-responsive">
                            <table class="table table-hover">
                              <tr>
                                <th
                                  v-for="column in corporateSubTable"
                                  :key="column"
                                >
                                  {{ column }}
                                </th>
                              </tr>
                              <template v-for="hr in item['hrs']">
                                <tr
                                  v-for="(item, index) in hr['users']"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ item["name"] }}</span>
                                  </td>
                                  <td>
                                    <span>{{ item["email"] }}</span>
                                  </td>
                                  <td>
                                    <span>{{
                                      dateFormatter(item["dob"], false)
                                    }}</span>
                                  </td>
                                  <td>
                                    <span>{{ item["postcode"] }}</span>
                                  </td>

                                  <!--                                  <td><a
                                      class="btn btn-sm bg-success-light"
                                      data-toggle="modal"
                                      href="#personalUserModal"
                                      @click="sendInfo(item, 'put', 'corp')"
                                  >
                                    <i class="fe fe-pencil"></i>
                                  </a>
                                    <a
                                        data-toggle="modal"
                                        href="#deleteModal"
                                        class="btn btn-sm bg-danger-light"
                                    >
                                      <i class="fe fe-trash"></i>
                                    </a></td>-->
                                </tr>
                              </template>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </Table>
                  <!-- /corporate table   -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->

  <!-- Personal Modal -->
  <Modal modalClasses="modal fade" modalId="personalUserModal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="form.key">Update User</h5>
      <h5 class="modal-title" v-else>Add User</h5>
    </template>
    <form @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Name"
              required
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
                v-model="form.postcode"
                type="text"
                class="form-control"
                placeholder="Postalcode"
                required
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
              :value="form.email"
              type="email"
              class="form-control"
              placeholder="Email"
              :readonly="true"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
                :value="dob"
                type="date"
                class="form-control"
                placeholder="DOB"
                :readonly="true"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">
        {{ form.key ? "Update" : "Add" }}
      </button>
    </form>
  </Modal>
  <!-- /Personal Modal -->

  <!-- Corporate Modal -->
  <Modal modalClasses="modal fade" modalId="corporateUserModal">
    <template v-slot:header>
      <h5 class="modal-title" v-if="selectedUser">Update User</h5>
      <h5 class="modal-title" v-else>Add User</h5>
    </template>
    <form @submit.prevent="submitHandler">
      <div class="row form-row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="selectedUser.name"
              type="text"
              class="form-control"
              placeholder="Name"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <select v-model="selectedUser.plan" class="form-control">
              <option>Select Plan</option>
              <option value="2Hr">2Hr</option>
              <option value="3Hr">3Hr</option>
              <option>Plan3</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <input
              v-model="selectedUser.planstart"
              type="date"
              class="form-control"
              placeholder="Plan Start"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              v-model="selectedUser.email"
              type="email"
              class="form-control"
              placeholder="Email"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <input
              type="file"
              class="form-control"
              placeholder="Upload Document"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">Add</button>
    </form>
  </Modal>
  <!-- /Corporate Modal -->

  <!-- Delete Modal -->
  <Modal modalClasses="modal fade" modalId="deleteModal">
    <div class="form-content p-2 text-center">
      <h4 class="modal-title">Delete</h4>
      <p class="mb-4">Are you sure want to delete?</p>
      <button type="button" class="btn btn-primary mr-1 py-10">Yes</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal">
        No
      </button>
    </div>
  </Modal>
  <!-- /Delete Modal -->
</template>

<script>
import PageBreadcrum from "@/components/PageBreadcrum.vue";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

const personalTableColumns = [
  "Name",
  "Plan",
  "Status",
  "PlanStart",
  "Ends At (Days)",
  "Email",
  "DOB",
  "Postcode",
  "Device",
  "Action",
];

const corporateTableColumns = [
  "",
  "Company Name",
  "Plan",
  "Email",
  "Employees",
  "CompanyID",
  "PlanStart",
];

export default {
  name: "UserManagement",
  components: {
    PageBreadcrum,
    Table,
    Modal,
  },
  data() {
    return {
      personalTable: [...personalTableColumns],
      corporateTable: [...corporateTableColumns],
      corporateSubTable: ["Name", "Email", "DOB", "Postcode"],
      //selected user for modal
      selectedUser: "",
      form: {
        key: "",
        name: "",
        email: "",
        postcode: "",
      },
      dob: null,
      formAction: "post",
      managementDetails: "",
      filterQuery: "",
      searchByStatus:"",
      isPersonal:true,
    };
  },
  methods: {
    ...mapActions("UserManagement", ["getUsers", "updateUsers"]),
    ...mapActions("CorpManagement", [
      "getCorpUsers",
      // "updateCorpUsers",
    ]),
    createOrUpdate: async function () {
      const User = new FormData();
      User.append("name", this.form.name);
      User.append("email", this.form.email);
      User.append("postcode", this.form.postcode);
      User.append("dob", this.dob);

      if (this.formAction == "post") {
        // let res = await this.addUsers(User);
        // return res;
      } else if (this.formAction == "put") {
        User.append("key", this.form.key);
        User.append("_method", "PATCH");
        let res = await this.updateUsers(User);
        return res;
      }
    },
    submitHandler(e) {
      // let el= e.target.parentNode;
      // console.log(el);
      // el.parentNode.style.display="none";

      if (this.formAction == "delete") {
        this.deleteUsers(this.form.key)
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
          .catch((e) => {
            Swal.fire({
              title: "Error!",
              text: "Error occurred",
              icon: "error",
              confirmButtonText: "Cool",
            });
          });
      } else {
        this.createOrUpdate()
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: `Operation Successful!`,
              icon: "success",
              confirmButtonText: "Cool",
            });
          })
          .catch((e) => {
            console.log(e);
            Swal.fire({
              title: "Error!",
              text: "Error",
              icon: "error",
              confirmButtonText: "Cool",
            });
          });
      }
    },
    sendInfo(item, formAction) {
      this.formAction = formAction;
      this.form = { ...item };
      this.dob = this.localDateFormat(item.dob);
    },
    assignManagementDetails(values) {
      this.managementDetails = values;
    },
    subHandler(item, column) {
      if (column == "Plan") {
        // if (!item.sub) {
        //   return "-";
        // } else {
        //   let sub = item.sub.data[0];
        //   if(item.sub.trial) {
        //     return "Trial";
        //   }
        //   return sub.plan.nickname;
        // }
        if(item.sub_2.length)
          return item.sub_2[0].product_id;
          return "Not Purchased";
      }
      if (column == "Ends At (Days)") {
        // if (!item.sub) {
        //   return "-";
        // } else {
        //   var sub = item.sub;
        //   var _sub = item.sub.data[0];
        //   if(typeof sub.trial != 'undefined' && sub.trial) {
        //     return this.getNumberOfDays(null, _sub.trial_end * 1000);
        //   }
        //   return this.getNumberOfDays(null , _sub.current_period_end*1000);
        // }
        if(item.sub_2.length)
          return this.dateFormatter(item.sub_2[0].validity);
          return "Not Purchased";
      }
      if (column == "PlanStart") {
        /*if (!item.sub) {
          return "-";
        } else {
          let sub = item.sub.data[0];

          return this.dateFormatter(sub.start_date * 1000, true);
        }*/
        if(item.sub_2.length)
          return this.dateFormatter(item.sub_2[0].created_at);
          return "Not Purchased";
      }
      if (column == "Status") {
        if(item.sub_2.length)
        {
            switch (item.sub_2 && item.sub_2[0].status) {
            case 1:
              return "Active";
            case 2:
              return "Expired";
            case 3:
              return "Billing retry period";
            case 4:
              return "Billing grace period";
            case 5:
              return "Revoked";
          }
        }
        return "Not Purchased";
      }

      if (column == "Device") {
            switch (item.device_type) {
            case 0:
              return "Android";
            case 1:
              return "IOS";
          }
        return "-";
      }
    },
    updateList(url) {
      this.getUsers(url)
    },
    updateCorpList(url) {
      this.getCorpUsers(url)
    },
    getNumberOfDays(start = null , end) {
  const date1 = new Date(start ??  (new Date().getTime()));
  const date2 = new Date(end);
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  return Math.round(diffInTime / oneDay);
},
    filterTable() {
      this.getUsers(`users?user_type=individual&query=${encodeURI(this.filterQuery)}&status=${encodeURI(this.searchByStatus)}`);
      this.getCorpUsers(`admin/company?query=${encodeURI(this.filterQuery)}`);
    },

  },
  mounted() {
    this.getUsers();
    this.getCorpUsers();
  },
  computed: {
    ...mapGetters("UserManagement", ["users", "meta"]),
    ...mapGetters("CorpManagement", ["corpuser", "corpmeta"]),
  },
};
</script>

<style>
</style>