import Cookies from 'js-cookie';
import axios from 'axios';
import { TOKEN } from './constants';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css'
import Swal from "sweetalert2";
import EH from '../../mixins/globals';
import router from '../../router/index';

const token = Cookies.get(TOKEN);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
/*if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}*/

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  const token = Cookies.get(TOKEN);
  if (token) {
    config.headers.Authorization =  `Bearer ${token}`;
  }
  nProgress.start();
  return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  nProgress.done();
  return response
}, error => {
  const [title, text] = EH.methods.errorHandler(error.response.data);
  Swal.fire({
    title: title,
    html: text,
    icon: "error",
    confirmButtonText: "Cool",
  });
  const route_name = router.currentRoute.value.name;
  console.log(route_name);
  if (route_name != 'Admin' && route_name != 'HR')
      window.location = process.env.VUE_APP_PROD_URL;
  return error
}, )

