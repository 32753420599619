<template>
  <div>
    <HrHeader pageHeading="Workouts" pageSubHeading="Mentally Happy" />
    <HrSidebar />
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-9">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <Card cardClasses="card hr-card">
                  <template v-slot:default>
                    <div class="row">
                      <div class="col-sm-6">
                        <h3 class="chart-title">Mentally Happy Overview</h3>
                        <div class="chart-details">
                          <p>Total watched videos (including multiple times) : {{ total_watched }}</p>
                          <p>Total videos : {{ total_video }}</p>
                          <p>Average watch time : {{ avg }}</p>
                        </div>
                      </div>
                      <div class="col-sm-3 offset-sm-3">
                        <select class="form-control" v-model="selected" @change="updateChart">
                         <option value="M">Monthly</option>
                          <option value="W">Weekly</option>
                          <option value="D">Daily</option>
                        </select>
                      </div>
                    </div>
                    <Vue3ChartJs
                      :id="mentallyChart.id"
                      :type="mentallyChart.type"
                      :data="mentallyChart.data"
                      ref="pChart"
                    ></Vue3ChartJs>
                  </template>
                </Card>
              </div>
            </div>

            <div class="row widgets-section">
              <div class="col col-xl-4 col-sm-6 col-12">
                <Card cardClasses="card hr-card widget-card">
                  <template v-slot:header>
                    <h4 class="card-title text-center">Videos</h4>
                  </template>
                  <template v-slot:default>
                    <div
                      class="custom-card-row d-flex"
                      v-for="row in videoCardData"
                      :key="row"
                      v-if="videoCardData.length"
                    >
                      <div class="custom-card-row-content">
                        <img :src="row.image" />
                        {{ row.content }}
                      </div>
                      <div class="custom-card-row-right">
                        <i class="fa fa-arrow-up"></i> {{ row.percentage }}
                      </div>
                    </div>
                    <div  class="text-center" v-else>No Data Found.</div>
<!--                    <div class="custom-card-fixed-bottom">
                      <a href="#">View Details</a>
                    </div>-->
                  </template>
                </Card>
              </div>
              <div class="col col-xl-4 col-sm-6 col-12">
                <Card cardClasses="card hr-card widget-card">
                  <template v-slot:header>
                    <h4 class="card-title text-center">Categories</h4>
                  </template>
                  <template v-slot:default>
                    <div
                      class="custom-card-row d-flex"
                      v-for="row in categoryCardData"
                      :key="row"
                      v-if="categoryCardData.length"
                    >
                      <div class="custom-card-row-content">
                        {{ row.content }}
                      </div>
                      <div class="custom-card-row-right">
                        <i class="fa fa-arrow-up"></i> {{ row.percentage }}
                      </div>
                    </div>
                    <div  class="text-center" v-else>No Data Found.</div>
<!--                    <div class="custom-card-fixed-bottom">
                      <a href="#">View Details</a>
                    </div>-->
                  </template>
                </Card>
              </div>
              <div class="col col-xl-4 col-sm-6 col-12">
                <Card cardClasses="card hr-card widget-card">
                  <template v-slot:header>
                    <h4 class="card-title text-center">Trainers</h4>
                  </template>
                  <template v-slot:default>
                    <div
                      class="custom-card-row d-flex"
                      v-for="row in trainerCardData"
                      :key="row"
                      v-if="trainerCardData.length"
                    >
                      <div class="custom-card-row-content">
                        <img :src="row.image" />
                        {{ row.content }}
                      </div>
                      <div class="custom-card-row-right">
                        <i class="fa fa-arrow-up"></i> {{ row.percentage }}
                      </div>
                    </div>
                    <div  class="text-center" v-else>No Data Found.</div>
<!--                    <div class="custom-card-fixed-bottom">
                      <a href="#">View Details</a>
                    </div>-->
                  </template>
                </Card>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import Support from "@/views/HR/Support.vue";
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import axios from "axios";

const videoCardData = [];

const categoryCardData = [];

const trainerCardData = [];

const mentallyChart = {
  id: "mentally",
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "Watched",
        data: [],
        fill: true,
        backgroundColor: "#d77c2288",
        tension: 0.1,
      },
    ],
  },
};
const url = 'hr/stats';

export default {
  name: "MentallyHappy",
  components: {
    Card,
    Support,
    HrHeader,
    HrSidebar,
    Vue3ChartJs,
  },
  data() {
    return {
      selected:'M',
      mentallyChart,
      videoCardData: [...videoCardData],
      categoryCardData: [...categoryCardData],
      trainerCardData: [...trainerCardData],
      total_watched: 0,
      total_video:0,
      avg:0
    };
  },
  methods : {
    getDashboardStat(url) {
      axios.get(url)
          .then(res=> {
            this.mentallyChart.data.labels = [];
            this.mentallyChart.data.datasets[0].data = [];
            var avg = 0;
            var i = 0;
            for(var key in res.data.data) {
              this.mentallyChart.data.labels.push(key);
              const P = res.data.data[key]['M'];
              this.mentallyChart.data.datasets[0].data.push(P);
              avg+=P;
              i++;
            }
            this.total_video = res.data.total_video;
            this.total_watched = res.data.total_watched;
            if (i) this.avg = (avg/i).toFixed(1);
            else this.avg = 0;
            this.$refs.pChart.update();

            // video, category stats
            this.videoCardData = [];
            Object.entries(res.data.extra_stats.videos).map(([i,e]) => {
              this.videoCardData.push({
                image: e['img'],
                content: e['name'],
                percentage: this.setPercentage(e['count'], this.total_watched)+"%",
              })
            });
            this.categoryCardData = [];
            Object.entries(res.data.extra_stats.category).map(([i,e]) => {
              this.categoryCardData.push({
                content: e['name'],
                percentage: this.setPercentage(e['count'], this.total_watched)+"%",
              })
            });

            this.trainerCardData = [];
            Object.entries(res.data.extra_stats.extra).map(([i,e]) => {
              this.trainerCardData.push({
                image: e['img'],
                content: e['name'],
                percentage: this.setPercentage(e['count'], this.total_watched)+"%",
              })
            });
          })
          .catch(err=> console.log(err))
    },
    updateChart(evt) {
      this.getDashboardStat(url+`?split_graph=${evt.target.value}&graph_type=M`);
    }
  },
  mounted() {
    this.getDashboardStat(url+`?split_graph=M&graph_type=M`);
  }
};
</script>

<style>
</style>